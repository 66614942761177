export const CONF_EXPIRATION = [
    '14 dní',
    '3 týdny',
    '1 měsíc',
    '2 měsíce',
    '3 měsíce',
    '6 měsíců',
    '9 měsíců',
    '1 rok',
    'Volný text...'
];