// import {Route, Routes} from "react-router-dom";
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {deleteAuthInfo, getAuthInfo} from "../utils/auth";
import {useFetch} from "react-async";
import React, {useContext, useEffect, useState} from "react";
import PrescriptionCreate from "./prescription/prescriptionCreate/PrescriptionCreate";
import PrescriptionEdit from "./prescription/prescriptionEdit/PrescriptionEdit";
import Register from "./login/register";
import Login from "./login/login";
import axios from "axios";
import PrescriptionList from "./prescription/prescriptionList/PrescriptionList";
import PrescriptionExport from "./prescription/prescriptionExport/PrescriptionExport";
import PrescriptionChangesList from "./prescription/prescriptionChanges/PrescriptionChangesList";
import PrescriptionShow from "./prescription/prescriptionShow/PrescriptionShow";
import UserList from "./user/userList/UserList";
import UserCreate from "./user/userCreate/UserCreate";
import Home from "./home/Home";
import {apiUrl} from "../utils/config";
import PocExport from "./prescription/prescriptionExport/ExportTemplate";
import {AppContext} from "../App";
import ReactGA from "react-ga4";

const RequireForRoute = ({auth, guest, role, redirectUrl, children}) => {
    const navigate = useNavigate();
    const context = useContext(AppContext);

    if (auth) {
        if(context.user.role === "superAdmin"){
            return children;
        }
        if (context.user && context.user.role === role){
            return children;
        } else {
            return <Navigate to={redirectUrl}/>;
        }
    } else if (guest) {
        if (context.token || context.user) {
            return <Navigate to={redirectUrl}/>;
        }
        return children;
    } else {
        return children;
    }

}

export default function Router() {
    const context = useContext(AppContext);

    useEffect(() => {
        ReactGA.send("pageview");
    }, []);

    return (

        <Routes>
            {
                context.agreed && (
                    <>
                        <Route
                            path="/prescription/create"
                            element={
                                <RequireForRoute redirectUrl={'/login'}
                                                 auth={true}
                                                 role={'admin'}
                                >
                                    <PrescriptionCreate/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription/:slug"
                            element={
                                <RequireForRoute>
                                    <PrescriptionShow/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription/:slug/edit"
                            element={
                                <RequireForRoute redirectUrl={'/login'}
                                                 auth={true}
                                                 role={'admin'}
                                >
                                    <PrescriptionEdit/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription/:slug/export"
                            element={
                                <RequireForRoute
                                    // redirectUrl={'/'}
                                    // auth={true}
                                    // role={'admin'}
                                >
                                    <PrescriptionExport/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription/:slug/exportResult"
                            element={
                                <RequireForRoute
                                    // redirectUrl={'/'}
                                    // auth={true}
                                    // role={'admin'}
                                >
                                    <PocExport/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription/:slug/changes"
                            element={
                                <RequireForRoute redirectUrl={'/prescription'}
                                                 auth={true}
                                                 role={'admin'}
                                >
                                    <PrescriptionChangesList/>
                                </RequireForRoute>
                            }
                        />

                        <Route
                            path="/prescription"
                            element={
                                <PrescriptionList/>
                            }
                        />

                        <Route

                            path="/user/create"
                            element={
                                <RequireForRoute redirectUrl={'/'}
                                                 auth={true}
                                                 role={'superAdmin'}
                                >
                                    <UserCreate/>
                                </RequireForRoute>

                            }
                        />

                        <Route
                            path="/user"
                            element={
                                <RequireForRoute redirectUrl={'/'}
                                                 auth={true}
                                                 role={'superAdmin'}
                                >
                                    <UserList/>
                                </RequireForRoute>
                            }
                        />

                        <Route path="/login" element={
                            <RequireForRoute redirectUrl={'/prescription'}
                                             guest={true}
                            >
                                <Login/>
                            </RequireForRoute>
                        }/>
                    </>)
            }

            <Route path="/" element={
                <RequireForRoute redirectUrl={'/prescription'}
                                 guest={true}
                >
                    <Home/>
                </RequireForRoute>
            }/>

            <Route path="*" element={<Home/>}/>
        </Routes>
    );
}