import OpenSans from '../../../css/fonts/OpenSansMedium.ttf'

import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

Font.register({
    family: "Open Sans",
    src: OpenSans,
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontSize: 10
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    header: {
        margin: 10,
        padding: 10,
        marginTop: 5,
    },
    main: {
        marginHorizontal: 10,
        paddingHorizontal: 10,
    },
    h1: {
        fontSize: 18,
        fontWeight: 900
    },
    h2: {
        fontSize: 16,
        textDecoration: 'underline',
        marginBottom: 15,
    },
    pharmaName: {
        marginVertical: 7,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },
    underline: {
        textDecoration: 'underline',
        marginRight: 5
    },
    text: {
        maxWidth: 450,
    },
    table: {
        margin: 20,
        marginTop: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        border: '1 solid #000',
    },
    tr: {
        display: "flex",
        flexDirection: "row",
        borderBottom: '1 solid #000',
    },
    trNoBorder: {
        display: "flex",
        flexDirection: "row"
    },
    nameTd: {
        paddingVertical: 3,
        textOverflow: "ellipsis",
        width: 350,
        marginRight: 10,
        paddingLeft: 4,
    },
    td: {
        paddingVertical: 3,
        textOverflow: "ellipsis",
        width: 180,
        marginRight: 10,
        borderLeft: '1 solid #000',
        paddingLeft: 4,
    },
    note: {
        paddingLeft: 4,
        paddingBottom: 30
    },
    ingredients: {
        display: 'flex',
        flexDirection: 'column'
    },
});

function ExportTemplate({pharmaName, prescription}) {

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.header}>
                    <Text style={styles.pharmaName}>{pharmaName}</Text>
                </View>

                <View style={styles.main}>
                    <Text style={styles.h2}>{prescription.name}</Text>


                    <View style={styles.row}>
                        <Text style={styles.underline}>Složení: </Text>

                        <View style={styles.ingredients}>
                            <Text style={styles.text}>Rp.</Text>
                            {prescription.Ingredients.map((ingredient) =>
                                (<Text style={styles.text} key={`${ingredient.name}_${ingredient.amount}`}>
                                    {ingredient.name} {ingredient.amount} {'\n'}
                                </Text>)
                            )}
                        </View>
                    </View>

                </View>

            </Page>
        </Document>
    );
}

export default ExportTemplate;