import {Link, useNavigate, useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";
import DeletePrescriptionModal from "../../modals/DeletePrescriptionModal";
import axios from "axios";
import SuccessModal from "../../modals/SuccessModal";
import FailModal from "../../modals/FailModal";
import ChangeStateModal from "../../modals/ChangeStateModal";
import {apiUrl} from "../../../utils/config";
import {AppContext} from "../../../App";

export default function PrescriptionSidebarPanel({slug, onDeleteClick, state, name}) {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(AppContext);
    const [deleteShow, setDeleteShow] = useState(false);
    const [approveShow, setApproveShow] = useState(false);
    const [toAprroveShow, setToAprroveShow] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [failModalShow, setFailModalShow] = useState(false);
    const [changeStateShow, setChangeStateShow] = useState(false);
    const [newState, setNewState] = useState(null);

    const deleteHandler = () => {
        console.debug('deleting');
        axios
            .delete(`${apiUrl}/api/prescriptions/${slug}`, {
                    headers: {
                        Authorization: context.token
                    }
                })
            .then((response) => {
                if (response.status === 204) {
                    console.debug(response.data);
                    setDeleteShow(false);
                    setSuccessModalShow(true);
                    setTimeout(function () {
                        navigate('/prescription');
                    }, 1500);
                }
            }).catch((err) => {
            console.debug(err);
            setDeleteShow(false);
            setFailModalShow(true);
            setTimeout(function () {
                setFailModalShow(false)
            }, 1500);
        });
    }

    const stateHandler = (state) => {
        console.debug('changing state');
        console.debug(state);
        axios
            .patch(`${apiUrl}/api/prescriptions/${slug}`, {
                state: state
            },{
                headers: {
                    Authorization: context.token
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.debug(response.data);
                    setChangeStateShow(false);
                    setSuccessModalShow(true);
                    setTimeout(function () {
                        setSuccessModalShow(false);
                        window.location.reload();
                    }, 1500);
                }
            }).catch((err) => {
            console.debug(err);
            setFailModalShow(true);
            setTimeout(function () {
                setFailModalShow(false)
            }, 1500);
        });
    }

    return (
        <ul className={"no-bullets edit-pane"} style={{
            background: '#fff',
            margin: '0 0.5rem 0 0.5rem',
            padding: '.8rem',
            borderRadius: ".25rem",
        }}>
            <DeletePrescriptionModal
                name={name}
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                handleSuccess={() => deleteHandler()}
            />
            <SuccessModal show={successModalShow}/>
            <FailModal show={failModalShow}/>
            <ChangeStateModal newState={newState}
                              oldState={state}
                              name={name}
                              show={changeStateShow}
                              handleClose={() => {
                                  setChangeStateShow(false)
                              }}
                              handleSuccess={stateHandler}
            />

            <li className="nav-item">
                <Link to={"/prescription/" + slug} className="nav-link" >
                    <i className="bi bi-search"></i>
                    <span>Detail</span>
                </Link>
            </li>
            {
                state === 'concept' && (
                    <li className="nav-item"
                        onClick={() => {
                            setNewState('toApprove');
                            setChangeStateShow(true);
                        }}
                    >
                        <div className="nav-link">
                            <i className="bi bi-card-checklist" ></i>
                            <span>Zaslat ke schvalování</span>
                        </div>

                    </li>
                )
            }
            {
                state === 'toApprove' && (
                    <li className="nav-item"
                        onClick={() => {
                            setNewState('approved');
                            setChangeStateShow(true);
                        }}
                    >
                        <div className="nav-link">
                            <i className="bi bi-check2-circle" ></i>
                            <span>Schválit a zveřejnit</span>
                        </div>
                    </li>
                )
            }
            {
                state === 'approved' && (
                    <li className="nav-item"
                        onClick={() => {
                            setNewState('toApprove');
                            setChangeStateShow(true);
                        }}
                    >
                        <div className="nav-link">
                            <i className="bi bi-eye-slash" ></i>
                            <span>Vrátit do schvalování</span>
                        </div>
                    </li>
                )
            }

            <li className="nav-item">
                <Link to={"/prescription/" + slug + "/edit"} className="nav-link" >
                    <i className="bi bi-gear-fill" ></i>
                    <span>Úprava</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to={"/prescription/" + slug + "/changes"} className="nav-link" >
                    <i className="bi bi-list-columns" ></i>
                    <span>Seznam změn</span>
                </Link>
            </li>
            <li className="nav-item">
                <div className="nav-link"
                     style={{
                         color: "#cb7057",
                         cursor: "pointer",
                     }}
                     onClick={() => {
                         setDeleteShow(true)
                     }}
                >

                    <i className="bi bi-trash-fill" style={{
                        color: "#cb7057",
                    }}></i>
                    <span>Vymazat</span>
                </div>
            </li>
        </ul>
    );
}