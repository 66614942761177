export default function NameSign({sign}){

    const styleCommon = {
        color: "#fff",
        padding: "2px 6px",
        marginRight:"2px",
        borderRadius:".25rem",
    };


    if(sign && (sign == 1 || sign == 2) ) {
        return (
            <span
                className="ml-3"
                style={{
                    ...styleCommon,
                    background: sign == 1 ? "#ff3366" : "#FFD000",
                }}
            >
             {sign == 1 ? "Novinka" : "Změna"}
            </span>
        );
    }
    return null;
}