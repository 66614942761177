import PrescriptionExport from "../prescription/prescriptionExport/PrescriptionExport";
import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {setAuthInfo} from "../../utils/auth";
import {useNavigate} from "react-router-dom";
import FailModal from "../../components/modals/FailModal";
import {AppContext} from "../../App";
import {apiUrl} from "../../utils/config";

export default function Login({pushLink = '/doctor/list'}) {

    const navigation = useNavigate();
    const context = useContext(AppContext);

    const loginHandler = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/api/user/login`, {email, password}).then((response) => {
            context.login(response.data.token, response.data.user);
            navigation('/prescription')
        }).catch((err) => {
            setShowFailModal(true);
            setTimeout(function () {
                setShowFailModal(false)
            }, 700);
        })
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showFailModal, setShowFailModal] = useState(false)


    return (
        <div className="container">

            <div className="row justify-content-center">

                <div className="col-6">


                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Vítejte zpět</h1>
                                        </div>

                                        <form className="user" data-bitwarden-watching="1" onSubmit={(e)=>loginHandler(e)}>
                                            <div className="form-group">
                                                <input type="email" className="form-control form-control-user"
                                                       id="exampleInputEmail" aria-describedby="emailHelp"
                                                       placeholder="Emailová adresa"
                                                       onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-user"
                                                       id="exampleInputPassword" placeholder="Heslo"
                                                       onChange={e => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox small">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="customCheck"/>
                                                </div>
                                            </div>


                                            <button type='submit' className="btn btn-primary btn-user btn-block">
                                                    Přihlásit
                                                </button>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <FailModal show={showFailModal}/>

        </div>


    );

}