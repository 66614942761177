import Table from "react-bootstrap/Table";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import TopBar from "../../../components/menu/topbar/TopBar";
import StateFlag from "../../../components/StateFlag";
import Highlighter from "react-highlight-words";
import {Spinner} from "react-bootstrap";
import OrderButton from "./OrderButton";
import {AppContext} from "../../../App";
import ComponentForRole from "../../../components/ComponentForRole";
import PrescriptionSidebarPanel from "../../../components/menu/sidebar/PrescriptionSidebarPanel";
import {apiUrl} from "../../../utils/config";
import {getAuthInfo} from "../../../utils/auth";
import ReactGA from "react-ga4";
import NameSign from "../../../components/NameSign";


export default function PrescriptionList(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;

    ReactGA.send("pageview");

    const dropdownIconStyle = {
        fontSize: "1.5em",
        marginRight: "5px",
    };

    const [loading, setLoading] = useState(true);
    const [prescriptions, setPrescriptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [hightlightText, setHightlightText] = useState([]);
    const [approveModalShow, setApproveModalShow] = useState(false);
    const [toApprovalModalShow, setToApprovalModalShow] = useState(false);
    const [toConceptModalShow, setToConceptModalShow] = useState(false);
    const [changeStateId, setChangeStateId] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [order, setOrder] = useState(null);
    const [conceptsShow, setConceptsShow] = useState(true);
    const [toApproveShow, setToApproveShow] = useState(true);


    const getAllPrescriptions = () => {
        const searchText = searchParams.get('searchText');
        const order = searchParams.get('orderItem') ? {
            item: searchParams.get('orderItem'),
            order: searchParams.get('order')
        } : null;
        let attributes = getUrlWithAttributes(searchText, order);

        axios
            .get(`${apiUrl}/api/prescriptions${attributes}`, { headers: { Authorization: context.token } })
            .then((response) => {
                console.debug(response.data);
                setPrescriptions(response.data);
                setLoading(false);
            });
    }

    const getUrlWithAttributes = (searchText, order) => {
        let attributes = '';
        if (searchText) attributes += (`?searchText=${searchText}`);
        if (attributes === '' && order) {
            attributes += (`?orderItem=${order.item}&order=${order.order}`)
        } else if (attributes !== '' && order) {
            attributes += (`&orderItem=${order.item}&order=${order.order}`)
        }
        console.debug(attributes);
        return attributes;
    }

    const redirectWithAttributes = (searchText, order) => {
        navigate(getUrlWithAttributes(searchText, order));
        setTimestamp(Date.now());
    }

    // const handleChangeState = (stateToChange) => {
    //     console.debug("change state request");
    //     axios
    //         .put(
    //             `http://localhost:4000/api/prescriptions/id:${changeStateId}`,
    //             {
    //                 state: stateToChange,
    //             }
    //         )
    //         .then((response) => {
    //             console.debug(response);
    //             if (response.status === 200) {
    //                 setTimestamp(Date.now());
    //             }
    //         })
    //         .finally(() => {
    //             setApproveModalShow(false);
    //             setToApprovalModalShow(false);
    //             setToConceptModalShow(false);
    //             setChangeStateId(null);
    //         });
    // };

    useEffect(() => {
        const text = searchParams.get('searchText');
        if(text){
            setSearchText(text);
        }
        getAllPrescriptions();
    }, []);

    useEffect(() => {
        getAllPrescriptions();
    }, [timestamp]);

    useEffect(() => {
        
        setHightlightText([])
        if (searchText.length > 2) {
            const searchTextWithoutSpecials = removeSpecialLetters(searchText);
            const searchTextWithSpecials = addSpecialLetters(searchText);
            const letters = [];
            for(let i = 0; i < searchTextWithSpecials.length; i++){
                letters.push(searchTextWithSpecials[i]);
            }
            for(let i = 0; i < searchTextWithoutSpecials.length; i++){
                letters.push(searchTextWithoutSpecials[i]);
            }
            setHightlightText(letters);
            console.debug(letters);
            redirectWithAttributes(searchText, order)
        } else {
            redirectWithAttributes(null, order)
        }
    }, [searchText]);

    useEffect(() => {
        if (searchText.length > 2) {
            redirectWithAttributes(searchText, order)
        } else {
            redirectWithAttributes(null, order)
        }
    }, [order]);

    const removeSpecialLetters = (searchText) =>{
        const czechAlphabet = "áčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ";
        const englishEquivalents = "acdeeinorstuuyzACDEEINORSTUUYZ";
    
        let searchTextWithoutSpecials = "";
            for (let i = 0; i < searchText.length; i++) {
                 let currentChar = searchText[i];
                 let index = czechAlphabet.indexOf(currentChar);
                 if (index !== -1) {
                   currentChar = englishEquivalents[index];
                 }
                   searchTextWithoutSpecials += currentChar;
               }
        
        return searchTextWithoutSpecials;
    }

    const addSpecialLetters = (searchText) =>{
        const czechAlphabet = "áčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ";
        const englishEquivalents = "acdeeinorstuuyzACDEEINORSTUUYZ";
    
        let searchTextWithoutSpecials = "";
            for (let i = 0; i < searchText.length; i++) {
                 let currentChar = searchText[i];
                 let index = englishEquivalents.indexOf(currentChar);
                 if (index !== -1) {
                   currentChar = czechAlphabet[index];
                 }
                   searchTextWithoutSpecials += currentChar;
               }
        
        return searchTextWithoutSpecials;
    }

    return (
        <>
            <div id="wrapper">

                <Sidebar role={role}>
                    {!loading && (
                        <ComponentForRole role={role} component={
                            <ul className={"no-bullets edit-pane"} style={{
                                background: 'white',
                                margin: '0px 8px 0px 8px',
                                padding: '.8rem 1rem',
                                borderRadius: "0.35rem",
                                fontSize: ".8rem",
                            }}>
                                <li>
                                    <label style={{
                                        cursor: 'pointer'
                                    }}>
                                        <input type="checkbox" name={'b'} checked={toApproveShow}
                                               onChange={() => setToApproveShow(!toApproveShow)} style={{
                                            margin: '0 1rem 0 0',
                                        }}/>
                                        Schvalovací proces
                                    </label>
                                </li>
                                <li>
                                    <label style={{
                                        cursor: 'pointer'
                                    }}>
                                        <input type="checkbox" name={'a'} checked={conceptsShow}
                                               onChange={() => setConceptsShow(!conceptsShow)} style={{
                                            margin: '0 1rem 0 0',
                                        }}/>
                                        Návrhy
                                    </label>
                                </li>
                            </ul>
                        }/>
                    )}
                </Sidebar>


                {/*{*/}
                {/*    role && role === "super" && (*/}
                {/*        <SupervisorSideBar/>*/}
                {/*    )*/}
                {/*}*/}

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar role={role} searchTextHandler={setSearchText} searchText={searchText}/>

                        {
                            loading ? (
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <Spinner animation="grow"/>
                                </div>
                            ) : (
                                <Table striped bordered hover className="table-responsive">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            <OrderButton setOrderHandler={setOrder} itemName={'name'}
                                                         searchParams={searchParams}/>
                                            Název IPLP
                                        </th>
                                        <th>
                                            <OrderButton setOrderHandler={setOrder} itemName={'unavailable'}
                                                         searchParams={searchParams}/>
                                            Název nedostupného HVLP nebo synonymum
                                        </th>
                                        <th>
                                            <OrderButton setOrderHandler={setOrder} itemName={'form'}
                                                         searchParams={searchParams}/>
                                            Léková forma dle ČL
                                        </th>
                                        <th>
                                            <OrderButton setOrderHandler={setOrder} itemName={'indication'}
                                                         searchParams={searchParams}/>
                                            Indikační skupina
                                        </th>
                                        <th>
                                        <OrderButton setOrderHandler={setOrder} itemName={'date'}
                                                        numbers={true}
                                                         searchParams={searchParams}/>
                                            Datum změny
                                        </th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prescriptions.filter((item) => {
                                        return !conceptsShow ? item.state !== 'concept' : item;
                                    }).filter((item) => {
                                        return !toApproveShow ? item.state !== 'toApprove' : item;
                                    }).map((item, i) => {
                                        return (
                                            <tr key={item.id} style={{
                                                cursor: 'pointer',
                                            }}
                                                onClick={() => {
                                                    navigate(`${item.slug}`);
                                                }}
                                            >

                                                <td role={role ? role : null}>
                                                    {item.state &&
                                                    (item.state ===
                                                    "concept" ? (
                                                        <span
                                                            style={{
                                                                background:
                                                                    "#EC9A29",
                                                                color: "#fff",
                                                                padding:
                                                                    "2px 6px",
                                                                borderRadius:
                                                                    ".25rem",
                                                            }}
                                                        >
                                                    {i + 1}
                                                </span>
                                                    ) : item.state ===
                                                    "toApprove" ? (
                                                        <span
                                                            style={{
                                                                background:
                                                                    "#FF3366",
                                                                color: "#fff",
                                                                padding:
                                                                    "2px 6px",
                                                                borderRadius:
                                                                    ".25rem",
                                                            }}
                                                        >
                                                    {i + 1}
                                                </span>
                                                    ) : (<span
                                                        style={{
                                                            padding:
                                                                "2px 6px",
                                                        }}
                                                    >
                                                    {i + 1}
                                                </span>))}

                                                </td>
                                                <td role={role ? role : null}>
                                                    <Highlighter
                                                        searchWords={hightlightText}
                                                        autoEscape={true}
                                                        textToHighlight={item.name}
                                                        highlightTag={'span'}
                                                    />

                                                    <NameSign sign={item.sign}/>

                                                </td>
                                                <td role={role ? role : null}>
                                                    {item.UnavailableDrugs.map((drug) => {
                                                        return <span
                                                            key={drug.name}
                                                            className={'me-1 badge bg-secondary fs-6'}>
                                                    <Highlighter
                                                        searchWords={hightlightText}
                                                        autoEscape={true}
                                                        textToHighlight={drug.name}
                                                        highlightTag={'span'}
                                                    />
                                                </span>;
                                                    })}
                                                </td>
                                                <td role={role ? role : null}>
                                                    <Highlighter
                                                        searchWords={hightlightText}
                                                        autoEscape={true}
                                                        textToHighlight={item.DosageForm.name}
                                                        highlightTag={'span'}
                                                    />
                                                </td>

                                                <td role={role ? role : null}>
                                                    <Highlighter
                                                        searchWords={hightlightText}
                                                        autoEscape={true}
                                                        textToHighlight={item.Indication.name}
                                                        highlightTag={'span'}
                                                    />
                                                </td>
                                                <td>
                                                    {new Date(item.updatedAt.replace(' ', 'T')).toLocaleDateString()}
                                                </td>
                                                <td>
                                                    <StateFlag state={item.state}/>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
}