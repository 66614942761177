import Sidebar from "../../../components/menu/sidebar/Sidebar";
import {Button, Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import TopBar from "../../../components/menu/topbar/TopBar";
import {AppContext} from "../../../App";
import ComponentForRole from "../../../components/ComponentForRole";
import PrescriptionSidebarPanel from "../../../components/menu/sidebar/PrescriptionSidebarPanel";
import SuccessModal from "../../../components/modals/SuccessModal";
import FailModal from "../../../components/modals/FailModal";
import StateFlag from "../../../components/StateFlag";
import {apiUrl} from "../../../utils/config";
import ExportTemplate from "../doctorExport/ExportTemplate";
import {PDFDownloadLink} from "@react-pdf/renderer";
import NameSign from "../../../components/NameSign";

export default function PrescriptionShow(props) {
    let params = useParams();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;

    const [field, setField] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [failModalShow, setFailModalShow] = useState(false);

    const [prescription, setPrescription] = useState(null);

    const styleForYes = {
        background:
            "#07ADBE",
        color: "#fff",
        padding:
            "2px 6px",
        marginRight:
            "2px",
        borderRadius:
            ".25rem",
    };


    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.debug('unable to copy to clipboard');
        }
    };

    const createCopyText = () => {
        let copyText = 'Rp. \n';
        prescription.Ingredients.forEach(ingredient => {
            copyText += `${ingredient.name} ${ingredient.amount} \n`;
        })
        return copyText;
    }

    const tooltipDoctor = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Pro lékaře
        </Tooltip>
    );

    const tooltipDoctorCopy = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Předpis pro lékaře - lékařský předpis se zkopíruje do schránky. Následně vložte pomocí CTRL(CMD)+V.
        </Tooltip>
    );

    const tooltipPharmacist = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Pro lékárníka
        </Tooltip>
    );

    useEffect(() => {
        console.debug("fetching data");
        axios
            .get(`${apiUrl}/api/prescriptions/${params.slug}`, {
                headers: {
                    Authorization: context.token
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.debug(response.data);
                    setPrescription(response.data);
                }
            })
            .catch((error) => {
                navigate('/prescription');
            })

        //TODO - error handling
    }, []);


    return (
        <>
            <div id="wrapper">
                <SuccessModal show={successModalShow}/>
                <FailModal show={failModalShow}/>

                <Sidebar role={role}>
                    {prescription && (
                        <ComponentForRole role={role} component={
                            <PrescriptionSidebarPanel slug={prescription.slug}
                                                      state={prescription.state}
                                                      name={prescription.name}
                            />
                        }/>
                    )}
                </Sidebar>

                {/*{*/}
                {/*    role && role === "super" && (*/}
                {/*        <SupervisorSideBar/>*/}
                {/*    )*/}
                {/*}*/}

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">

                        <TopBar role={role}/>

                        {prescription ? (
                            <div className="container-fluid">
                                <div className="d-none d-md-block">
                                    <div className="d-flex justify-content-between mb-4">
                                        <div className="col d-flex justify-content-end">
                                            <StateFlag state={prescription.state}/>
                                        </div>
                                        <div className="col d-flex justify-content-end">

                                            <div>
                                                <PDFDownloadLink
                                                    document=
                                                        {<ExportTemplate
                                                            prescription={prescription}
                                                        />}
                                                    fileName={`${params.slug}.pdf`}
                                                >
                                                    <Button
                                                        className="d-none d-sm-inline-block btn btn-primary shadow-sm mr-2">
                                                        <i className="bi bi-file-earmark-medical"></i>{" "}
                                                        Export lékařského předpisu
                                                    </Button>
                                                </PDFDownloadLink>

                                                <div className="mt-2">
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={tooltipDoctor}
                                                    >
                                                        <i className="bi bi-info-circle"></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>

                                            <div>
                                                <Link
                                                    to={`export`}
                                                    className="d-none d-sm-inline-block btn btn-primary shadow-sm"
                                                >

                                                    <i className="bi bi-file-earmark-pdf"></i>{" "}
                                                    Export technologického předpisu
                                                </Link>

                                                <div className="mt-2">
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={tooltipPharmacist}
                                                    >
                                                        <i className="bi bi-info-circle"></i>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název IPLP
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.name}
                                                <NameSign sign={prescription.sign}/>
                                            </div>
                                        </div>
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název nedostupného HVLP nebo synonymum
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.UnavailableDrugs.map((drug) => {
                                                    return <span
                                                        className={'me-1 badge bg-secondary fs-6'}>{drug.name}</span>;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3 d-flex flex-row justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Předpis pro lékaře
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                <ul className="no-bullets p-0 m-0">
                                                    {/* Rp. is an fix value, please don't delete it */}
                                                    <li>Rp.</li>
                                                    {prescription.Ingredients.map(
                                                        (item) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}{" "}
                                                                    {
                                                                        item.amount
                                                                    }
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>

                                                <div className={"float-right"}>
                                                    <span className="mr-2 d-none d-md-inline-block">
                                                        <OverlayTrigger
                                                            placement="left"
                                                            delay={{show: 250, hide: 400}}
                                                            overlay={tooltipDoctorCopy}
                                                        >
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </span>

                                                    <Button onClick={() => copyToClipboard(createCopyText())}>
                                                        <i className="bi bi-clipboard-check"/> {" "}
                                                        Kopírovat
                                                    </Button>

                                                </div>
                                            </div>
                                        </div>
                                        <Row>
                                            <div className="col-md-6">
                                                <div className="card mb-4">
                                                    <div className="card-header py-3">
                                                        <h6 className="m-0 font-weight-bold text-primary">
                                                            Úhrada pojišťovny
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                <span
                                                    style={
                                                        prescription.insurancePayment
                                                            ? styleForYes
                                                            : {}
                                                    }
                                                >
                                                    {prescription.insurancePayment
                                                        ? "ano"
                                                        : prescription.insurancePayment === false

                                                            ? "ne"
                                                            : prescription.insurancePayment}
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card mb-4">
                                                    <div className="card-header py-3">
                                                        <h6 className="m-0 font-weight-bold text-primary">
                                                            Poznámka k úhradě
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        {prescription.noticeInsurancePayment}
                                                    </div>
                                                </div>
                                            </div>

                                        </Row>
                                    </div>
                                </div>
                                <h5 className="profession-title">Pro lékárníka</h5>

                                <Row>
                                    <div className="col-md-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Indikační skupina
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.Indication.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Léková forma dle ČL
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.DosageForm.name}
                                            </div>
                                        </div>
                                    </div>
                                </Row>

                                {
                                    prescription.Substances.length > 0 && (
                                        <Row>
                                            <Col md={6}>
                                                <div className="card mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">
                                                            Suroviny / pomůcky pro lékárníka
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="no-bullets p-0 m-0">
                                                            {prescription.Substances.map(
                                                                (item) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            {item.name}{" "}
                                                                            {
                                                                                item.amount
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}></Col>
                                        </Row>
                                    )
                                }

                                <div className='row mt-5'>
                                    <div className="col-md-6">

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Obal I.
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.container}
                                            </div>
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Doba použitelnosti I.
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.expirationFirst}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-md-6'>
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Postup přípravy I.
                                                </h6>
                                            </div>
                                            <div className="card-body"
                                                 dangerouslySetInnerHTML={{__html: prescription.preparationProcess}}>
                                                {/*{prescription.preparationProcess}*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    prescription.secondContainer && (
                                        <div className='row mt-5'>
                                            <div className="col-md-6">

                                                <div className="card mb-4">
                                                    <div className="card-header py-3">
                                                        <h6 className="m-0 font-weight-bold text-primary">
                                                            Obal II.
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        {prescription.secondContainer}
                                                    </div>
                                                </div>

                                                <div className="card mb-4">
                                                    <div className="card-header py-3">
                                                        <h6 className="m-0 font-weight-bold text-primary">
                                                            Doba použitelnosti II.
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        {prescription.expirationSecond}
                                                    </div>
                                                </div>

                                            </div>
                                            {
                                                prescription.secondPreparationProcess && (
                                                    <div className='col-md-6'>
                                                        <div className="card mb-4">
                                                            <div className="card-header py-3">
                                                                <h6 className="m-0 font-weight-bold text-primary">
                                                                    Postup přípravy II.
                                                                </h6>
                                                            </div>
                                                            <div className="card-body"
                                                                 dangerouslySetInnerHTML={{__html: prescription.secondPreparationProcess}}>
                                                                {/*{prescription.preparationProcess}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                
                                <div className='row mt-5'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                    <div className="card mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">
                                                BEZPEČNOSTNÍ POKYNY PRO PŘÍPRAVU
                                            </h6>
                                            </div>
                                                <div className="card-body"
                                                    dangerouslySetInnerHTML={{__html: prescription.safetyPreparationProcess}}>
                                                </div>
                                            </div>
                                    </div>
                                </div>



                                <div className='row mt-5'>

                                    <div className='col-md-6'>
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Označení
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                <span
                                                    style={
                                                        prescription.labelColor ===
                                                        "red" ||
                                                        prescription.labelColor ===
                                                        "červená"
                                                            ? {
                                                                background:
                                                                    "#ff0000",
                                                                color: "#fff",
                                                                padding:
                                                                    "2px 6px",
                                                                marginRight:
                                                                    "2px",
                                                                borderRadius:
                                                                    ".25rem",
                                                            }
                                                            : {}
                                                    }
                                                >
                                                    {prescription.labelColor ===
                                                    "red"
                                                        ? "Červená signatura"
                                                        : prescription.labelColor ===
                                                        "white"
                                                            ? "Bílá signatura"
                                                            : prescription.labelColor}
                                                </span>
                                            </div>
                                        </div>


                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Upozornění pro pacienta
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.noticeForPatient}
                                            </div>
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Zdroj receptury
                                                </h6>
                                            </div>
                                            <div className="card-body" dangerouslySetInnerHTML={{__html:prescription.source}}></div>
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                Režim výdeje
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.freeTextSale}
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-md-6">

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Podmínky uchovávání
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.storageConditions}
                                            </div>
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Kontrola / vzhled
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.control}
                                            </div>
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Poznámky
                                                </h6>
                                            </div>
                                            <div className="card-body" dangerouslySetInnerHTML={{__html:prescription.comment}}></div>                                           
                                        </div>

                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Volný text
                                                </h6>
                                            </div>
                                            <div className="card-body">{prescription.freeText}</div>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <Spinner animation="grow"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
