export default function StateFlag({state}) {
    if (state !== null) {
        if (state === 'concept') {
            return (
                <div
                    style={{
                        background:
                            "#EC9A29",
                        color: "#fff",
                        width: "100%",
                        padding:
                            "2px 6px",
                        borderRadius:
                            ".25rem",
                    }}
                >
                    Návrh
                </div>
            );
        }

        if (state === "toApprove") {
            return (
                <div
                    style={{
                        background:
                            "#FF3366",
                        color: "#fff",
                        padding:
                            "2px 6px",
                        width: "100%",
                        borderRadius:
                            ".25rem",
                    }}
                >
                    Schvalovací proces
                </div>
            );
        }
    }
    return null;
}