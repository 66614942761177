import {Form} from "react-bootstrap";
import React, {useState} from "react";

export default function TextInputWithWhisperer({
                                                   label,
                                                   value,
                                                   onChangeHandler,
                                                   onChangeHandlerEvent,
                                                   whispers = [],
                                                   showOnChange = false,
                                                   onWhispClickHandler,
                                                   textArea=false
                                               }) {
    const [showWhisperer, setShowWhisperer] = useState(false);
    return (
        <Form.Group>
            {
                label && (
                    <Form.Label>{label}</Form.Label>
                )
            }
            <Form.Control
                {...(textArea ? {as: "textarea"} : {type: "string"})}
                value={value}
                onChange={(e) => {
                    if (onChangeHandlerEvent) {
                        onChangeHandlerEvent(e)
                    } else {
                        onChangeHandler(e.target.value);
                    }
                    if (!showOnChange){
                        setShowWhisperer(false);
                    }
                }}
                onFocus={() => setShowWhisperer(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setShowWhisperer(false);
                    }, 200)
                }}
            />
            {
                showWhisperer && (whispers.length > 0) && (
                    <div className="shadow rounded" style={{
                        position: 'absolute',
                        zIndex: '10',
                        background: '#fff',
                        border: "1px solid #ccc",
                        padding: '0 16px 0 4px',
                    }}>
                        {
                            whispers.map((whisp) => {
                                return <div style={{
                                    cursor: 'pointer'
                                }}
                                            key={whisp}
                                            onClick={() => {
                                                if (onChangeHandlerEvent) {
                                                    onChangeHandlerEvent({
                                                        target: {
                                                            value: whisp
                                                        }
                                                    });
                                                    if(onWhispClickHandler){
                                                        onWhispClickHandler();
                                                    }
                                                } else {
                                                    onChangeHandler(whisp);
                                                    if(onWhispClickHandler){
                                                        onWhispClickHandler();
                                                    }
                                                }
                                                setShowWhisperer(false)
                                            }}
                                >
                                    {whisp}
                                </div>
                            })
                        }
                    </div>
                )
            }

        </Form.Group>

    );
}