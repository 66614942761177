import React from 'react'
import PrescriptionExport from "../../../routes/prescription/prescriptionExport/PrescriptionExport";
import {Link} from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

const Sidebar = (props) => {
    return (
        <div className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-none d-md-block" id="accordionSidebar">
            <ul className={"no-bullets"}>
                <a className="sidebar-brand d-flex align-items-center justify-content-center">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-pills text-white"></i>
                    </div>
                    <Link to={"/"} style={{
                        textDecoration: "none",
                    }} >
                        <div className="mx-3 text-white">IPLP receptář</div>
                    </Link>
                </a>

                <li className="nav-item active">
                    <Link to={"/prescription"}>
                        <a className="nav-link">
                            <i className="fas fa-fw fa-home"></i>
                            <span>Domov</span>
                        </a>
                    </Link>
                </li>

                {
                    (props.role === "superAdmin" || props.role === "admin") && (
                        <Link
                            to="/prescription/create"
                        >
                            <li className="nav-item active">
                                <a className="nav-link">
                                    <i className="fas fa-fw fa-plus"></i>
                                    <span>Vytvořit recept</span>
                                </a>
                            </li>

                        </Link>
                    )
                }

            </ul>
            {
                props.children
            }

        </div>
    )
}

export default Sidebar;
