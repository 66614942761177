const LOCAL_STORAGE_AUTH_KEY = 'app-middlewares';

export const getAuthInfo = () => {
    if (!window.localStorage) {
        return null;
    }

    try {
        const rawData = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
        const { token, user } = JSON.parse(rawData);

        if (token) {
            return { token, user };
        }
    } catch (err) {
        return null;
    }
}

export const setAuthInfo = ( token, user) => {
    window.localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify({token, user}));
}

export const deleteAuthInfo = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
}