import {Card} from "react-bootstrap";

export default function InsurancePaymentButton({selected, color, onClickHandler}){
    return <Card style={{
        width: "8rem", cursor: "pointer",
    }}
    className={'d-inline-block text-center me-1 mb-1 cursor-pointer ' + (color === "red" ? ' bg-danger text-light' : (color === "green" ? ' bg-success text-light' : '')) +
    (selected === true ? ' border-primary border-4' : '')
    }
    onClick={() => onClickHandler()}
>
<Card.Body>
    {
        color === "red" ? (
            <span>Ne</span>
        ) : color === "green" ? (
            <span>Ano</span>
        ) : null
    }
    </Card.Body>
</Card>
}