import {Modal, Button, ModalBody, Row, Col} from "react-bootstrap";
import {useState} from "react";

export default function DeletePrescriptionModal({
                                                    show,
                                                    handleClose,
                                                    handleSuccess,
                                                    name
                                                }) {

    const [input, setInput] = useState("");
    const [error, setError] = useState(false);

    const confirmDelete = () => {
        if(input === name){
            handleSuccess();
        } else {
            setError(true);
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Smazat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'text-center'}>
                    <div>Určite chcete vymazat recept na lék <strong>{name}</strong>?</div>
                    <div>Tento krok není možné vrátit a data budou vymazána.</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={'d-flex justify-content-between w-100'}>
                    <Col xs={8}>
                        <input type="text" placeholder={"Zadejte název léku, zobrazený výše"}
                               value={input}
                               onChange={(e)=>{
                                   setError(false);
                                   setInput(e.target.value);
                               }}
                               style={{
                            borderRadius: '6px',
                            width: '100%',
                            borderColor: '#C00000',
                            color: '#C00000',
                        }}/>
                        {
                            error &&(
                                <span style={{
                                    color: '#C00000'
                                }}>Název se nezhoduje</span>
                            )
                        }
                        <div>
                            <Button
                                variant="danger"
                                onClick={confirmDelete}
                                className="mt-1"
                            >
                                Smazat
                            </Button>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div>
                            <Button variant="primary" onClick={handleClose}>
                                Zpět
                            </Button>
                        </div>
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>
    );
}
