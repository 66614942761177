import {Modal, Button} from "react-bootstrap";

export default function ChangeStateModal({
                                            show,
                                            name,
                                            newState,
                                            oldState,
                                            handleClose,
                                            handleSuccess,
                                        }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        newState === "toApprove" && oldState !== "approved" && (
                            <span>Zaslat ke schválení</span>
                        )
                    }
                    {
                        newState === "toApprove" && oldState === "approved" && (
                            <span>Vrátit do schvalování</span>
                        )
                    }
                    {
                        newState === "approved" && (
                            <span>Schválit a zveřejnit</span>
                        )
                    }
                    {
                        newState === "concept" && (
                            <span>Vrátit do schvalování</span>
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    newState === "toApprove" && oldState !== "approved" && (
                        <p>Zaslat ke schválení <strong>{name}</strong></p>
                    )
                }
                {
                    newState === "toApprove" && oldState === "approved" && (
                        <p>Vrátit do schvalovacího procesu <strong>{name}</strong></p>
                    )
                }
                {
                    newState === "approved" && (
                        <p>Schválit a zveřejnit <strong>{name}</strong></p>
                    )
                }
                {
                    newState === "concept" && (
                        <p>Vrátit do schvalování <strong>{name}</strong></p>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => handleSuccess(newState)}
                >
                    Ano
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    Ne
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
