export const CONF_SOURCES = [
    'SPC léčivého přípravku',
    'Seznam československých farmaceutických přípravků 1976-1978, SPOFA, Praha, 1975',
    'Pharmindex Kompendium 2002, MediMedia Information s.r.o., Praha 2001',
    'Český lékopis 2023, Grada Publishing a.s., Praha 2023',
    'Deutscher Arzneimittel-Codex /Neues Rezeptur-Formularium (DAC/NRF). Govi in der Avoxa. Eschborn. 2021',
    'Formularium der Nederlandse Apothekers (FNA). 4th Ed., 2009, Den Haag: KNMP',
    'Pharmindex Brevíř 1997; vyd. MediMedia Informations, 1997',
    'Praescriptiones Pharmaceuticae 1972; vyd. Ministerstvo zdravotnictví ČSR',
    'DAC/NRF-Rezepturhinweise (web po přihlášení)',
    'Volný text...',
]