import Table from "react-bootstrap/Table";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import TopBar from "../../../components/menu/topbar/TopBar";
import {AppContext} from "../../../App";
import {dateFormatted} from "../../../components/Formater";
import {Spinner} from "react-bootstrap";
import ComponentForRole from "../../../components/ComponentForRole";
import PrescriptionSidebarPanel from "../../../components/menu/sidebar/PrescriptionSidebarPanel";
import StateFlag from "../../../components/StateFlag";
import {apiUrl} from "../../../utils/config";

export default function PrescriptionChangesList(props) {
    const navigate = useNavigate();
    const params = useParams();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;

    const [prescription, setPrescription] = useState(null);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [failModalShow, setFailModalShow] = useState(false);

    useEffect(() => {
        console.debug("fetching data");
        axios
            .get(`${apiUrl}/api/prescriptions/${params.slug}`,{
                headers: {
                    Authorization: context.token
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.debug(response.data);
                    setPrescription(response.data);
                }
            });

        //TODO - error handling
    }, []);

    return (
        <>
            <div id="wrapper">


                <Sidebar role={role}>
                    {prescription && (
                        <ComponentForRole role={role} component={
                            <PrescriptionSidebarPanel slug={prescription.slug}
                                                      state={prescription.state}
                                                      name={prescription.name}
                                                      />
                        }/>
                    )}
                </Sidebar>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar role={context.user ? context.user.role : null}/>

                        {prescription ? (
                            <>
                                {
                                    prescription.state && (
                                        <div className="row">
                                            <div className="col ml-2 mb-2">
                                                <StateFlag state={prescription.state}/>
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                    )
                                }

                                <div className="row">
                                    <div className="col ml-2">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název IPLP
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mr-2">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název nedostupného HVLP
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.UnavailableDrugs.map((drug) => {
                                                    return <span
                                                        className={'me-1 badge bg-secondary fs-6'}>{drug.name}</span>;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Datum změny</th>
                                        <th>Komentář</th>
                                        <th>Autor</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prescription.PrescriptionChanges && prescription.PrescriptionChanges.map((item, i) => {
                                        return (
                                            <tr key={item.id}>

                                                <td>
                                                    {dateFormatted(item.createdAt)}
                                                </td>
                                                <td role={props.role ? props.role : null}>
                                                    {item.comment}
                                                </td>
                                                <td role={props.role ? props.role : null}>
                                                    {item.User.name}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <Spinner animation="grow"/>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}
