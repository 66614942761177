import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import SuccessModal from "../../../components/modals/SuccessModal"
import FailModal from "../../../components/modals/FailModal";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import ContainerButton from "../../../components/ContainerButton";
import LabelColorButton from "../../../components/LabelColorButton";
import {TextEditor, getEditorStateFromHTML, getEditorDataAsHTML} from "../../../components/editor/TextEditor";
import {convertToRaw, EditorState} from "draft-js";
import ButtonWithTooltip from "../../../components/ButtonWithTooltip";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../../../utils/config";

export default function UserCreate() {
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [failModalShow, setFailModalShow] = useState(false);

    const changeUserFieldHandler = (i, field, e) => {
        let newUsers = [...users];
        let updatedUser = {...newUsers[i]};
        updatedUser[field] = e.target.value;
        newUsers[i] = updatedUser;
        setUsers(newUsers);

        console.debug("changeUserFieldHandler - " + field);
        console.debug(users);
    };

    const deleteUserHandler = (i) => {
        let updatedUsers = [...users];
        updatedUsers.splice(i, 1);
        setUsers(updatedUsers);

        console.debug("deleteUserHandler");
        console.debug(users);
    };

    const addUserHandler = () => {
        setUsers((old) => {
            return [...old,
                {
                    name: "",
                    email: "",
                },
            ];
        });
        console.debug("addUserHandler");
        console.debug(users);
    };

    const submitFormHandler = (e) => {
        e.preventDefault();
        axios
            .post(`${apiUrl}/api/users`, {
                users: users
            })
            .then((response) => {
                console.debug(response);
                if (response.status === 201) {
                    setSuccessModalShow(true);
                    setTimeout(function () {
                        setSuccessModalShow(false)
                    }, 1500);
                    navigate('/user');
                }
            }).catch(() => {
            setFailModalShow(true);
            setTimeout(function () {
                setFailModalShow(false)
            }, 1500);
        });
    };


    return (

        <div id="wrapper">

            <Sidebar/>

            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">

                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item dropdown no-arrow d-sm-none">
                                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown"
                                   role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-search fa-fw"></i>
                                </a>

                                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                     aria-labelledby="searchDropdown">
                                    <form className="form-inline mr-auto w-100 navbar-search">
                                        <div className="input-group">
                                            <input type="text" className="form-control bg-light border-0 small"
                                                   placeholder="Search for..." aria-label="Search"
                                                   aria-describedby="basic-addon2"/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button">
                                                    <i className="fas fa-search fa-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </li>

                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown"
                                   role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-bell fa-fw"></i>
                                    <span className="badge badge-danger badge-counter">3+</span>
                                </a>
                                <div
                                    className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="alertsDropdown">
                                    <h6 className="dropdown-header">
                                        Alerts Center
                                    </h6>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="mr-3">
                                            <div className="icon-circle bg-primary">
                                                <i className="fas fa-file-alt text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">December 12, 2019</div>
                                            <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="mr-3">
                                            <div className="icon-circle bg-success">
                                                <i className="fas fa-donate text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">December 7, 2019</div>
                                            $290.29 has been deposited into your account!
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="mr-3">
                                            <div className="icon-circle bg-warning">
                                                <i className="fas fa-exclamation-triangle text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">December 2, 2019</div>
                                            Spending Alert: We've noticed unusually high spending for your
                                            account.
                                        </div>
                                    </a>
                                    <a className="dropdown-item text-center small text-gray-500" href="#">Show
                                        All
                                        Alerts</a>
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                   data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                        <span
                                            className="mr-2 d-none d-lg-inline text-gray-600 small">Douglas McGee</span>
                                    <img className="img-profile rounded-circle" src="img/undraw_profile.svg"/>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                     aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="#">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Settings
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Activity Log
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" data-toggle="modal"
                                       data-target="#logoutModal">
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>

                        </ul>

                    </nav>

                    <Container>
                        <SuccessModal show={successModalShow}/>
                        <FailModal show={failModalShow}/>

                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h3>Vytvoření uživatelů</h3>
                            </div>

                            <div className="card-body">

                                <Form
                                    onSubmit={submitFormHandler}
                                >
                                    <Row className="border border-2 pt-3 rounded mb-3">
                                        <Col>
                                            <Row className="mb-4">
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                users.length > 0 && (
                                                                    <Row>
                                                                        <Col><Form.Label>Jméno</Form.Label></Col>
                                                                        <Col><Form.Label>Email</Form.Label></Col>
                                                                        <Col xs={1}></Col>
                                                                    </Row>
                                                                )
                                                            }
                                                            {
                                                                users.map((item, i) => {
                                                                    return (
                                                                        <Row key={item.id}
                                                                             className="mb-1 d-flex">
                                                                            <Col>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Control
                                                                                            type="string"
                                                                                            value={item.name}
                                                                                            onChange={(e) => {
                                                                                                changeUserFieldHandler(
                                                                                                    i,
                                                                                                    "name",
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Form.Control
                                                                                            type="string"
                                                                                            value={item.email}
                                                                                            onChange={(e) => {
                                                                                                changeUserFieldHandler(
                                                                                                    i,
                                                                                                    "email",
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col xs={1}>
                                                                                <Button
                                                                                    style={{
                                                                                        fontSize: '2em',
                                                                                        padding: '0 0',
                                                                                        lineHeight: '1.2em',
                                                                                        background: 'inherit',
                                                                                        border: '0',
                                                                                        color: 'inherit',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        deleteUserHandler(i)
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-x"></i>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    );
                                                                })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col className={'d-flex justify-content-center'}>
                                                    <ButtonWithTooltip
                                                        iconName={'bi-plus'}
                                                        onClickHandler={addUserHandler}
                                                        tooltipText={'Přidej nového uživatele'}
                                                        tooltipPlacement={'left'}
                                                        key={'add-user-btn'}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                    <Button variant="success" type="submit">
                                        Registrovat uživatelé
                                    </Button>

                                </Form>

                            </div>
                        </div>
                    </Container>

                </div>
            </div>


        </div>
    )
        ;
}
