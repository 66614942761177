export const CONF_CONTROL = [
    "Bílá mast po pachu ...",
    "Bílá emulzní mast",
    "Bílá průsvitná mast",
    "Bílá suspenzní mast",
    "Světle žlutá mast",
    "Žlutá mast",
    "Žlutá emulzní mast",
    "Žlutá suspenzní mast",
    "Světle hnědá suspenzně-emulzní mast",
    "Bílá až nažloutlá stejnorodá pasta",
    "Bílý krém", "Bílý suspenzní krém",
    "Nažloutlý krém slabě páchnoucí",
    "Světle žlutý krém",
    "Bílá emulze hydrofilní (o/v) řídké konzistence",
    "Bílá stejnorodá suspenze, velmi hustá",
    "Žlutá stejnorodá suspenze",
    "Bezbarvý gel, lehce zakalený",
    "Čirá bezbarvá tekutina bez pachu",
    "Čirý bezbarvý roztok",
    "Čirá bezbarvá kapalina bez pachu",
    "Bezbarvá kapalina slabě zakalená",
    "Tobolky naplněné bílým až téměř bílým práškem",
    "Tobolky naplněné žlutým práškem",
    "Čípek bílý nebo slabě nažloutlý torpédovitého tvaru",
    "Průsvitná světle žlutá pružná kulička"
];