import OpenSans from '../../../css/fonts/OpenSansMedium.ttf'

import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

Font.register({
    family: "Open Sans",
    src: OpenSans,
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontSize: 10,
        paddingVertical: 30,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    header: {
        margin: 10,
        padding: 10,
        borderBottom: '1 solid #000',
    },
    main: {
        marginHorizontal: 10,
        paddingHorizontal: 10,
    },
    h1: {
        fontSize: 18,
        fontWeight: 900,
        paddingHorizontal: 0
    },
    h2: {
        fontSize: 16,
        textDecoration: 'underline',
        marginBottom: 15,
    },
    pharmaName: {
        marginVertical: 7,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },
    underline: {
        textDecoration: 'underline',
        marginRight: 5
    },
    text: {
        maxWidth: 450
    },
    table: {
        margin: 20,
        marginTop: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        border: '1 solid #000',
    },
    tr: {
        display: "flex",
        flexDirection: "row",
        borderBottom: '1 solid #000',
    },
    trNoBorder: {
        display: "flex",
        flexDirection: "row"
    },
    nameTd: {
        paddingVertical: 3,
        textOverflow: "ellipsis",
        width: 350,
        marginRight: 10,
        paddingLeft: 4,
    },
    td: {
        paddingVertical: 3,
        textOverflow: "ellipsis",
        width: 180,
        marginRight: 10,
        borderLeft: '1 solid #000',
        paddingLeft: 4,
    },
    note: {
        paddingLeft: 4,
        paddingBottom: 30
    },
    ingredients: {
        display: 'flex',
        flexDirection: 'column'
    },
});

const convertColorToCzech = (color) => {
    const labelContants = {
        red: 'Červená signatura',
        white: 'Bílá signatura'
    };
    return labelContants[color];
}

const convertExpiracyToCzech = (expiracy) => {
    return expiracy
        .replace('weeks', 'týdny')
        .replace('week', 'týden')
        .replace('days', 'dny')
        .replace('day', 'den')
}

const removeHtmlTags = (originalString) => {
    return originalString
        .replace(/<br>|\/<br>/gi, '\n')
        .replaceAll('</p>', '\n')
        .replaceAll('&nbsp;', '\u00A0')
        .replace(/(<([^>]+)>)/gi, "")
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
    // zkusit nejakej parser co vezme html znaky do unicodu
}

function ExportTemplate({pharmaName, writtenBy, writtenDate, approvedBy, approvedDate, note, prescription}) {

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.header}>
                    <Text style={styles.h1}>Technologický předpis</Text>
                    <Text style={styles.pharmaName}>{removeHtmlTags(pharmaName)}</Text>
                </View>


                <View style={styles.main}>
                    <Text style={styles.h2}>{removeHtmlTags(prescription.name)}</Text>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Složení: </Text>

                        <View style={styles.ingredients}>
                            {prescription.Substances.map((ingredient) =>
                                (<Text style={styles.text} key={`${ingredient.name}_${ingredient.amount}`}>
                                    {removeHtmlTags(ingredient.name)} {removeHtmlTags(ingredient.amount)}
                                </Text>)
                            )}
                        </View>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Postup přípravy I: </Text>
                        <Text style={{marginHorizontal: 4, maxWidth: styles.text.maxWidth}}>
                            {removeHtmlTags(prescription.preparationProcess)}
                        </Text>
                    </View>

                    {
                        (prescription.secondPreparationProcess !== null && prescription.secondPreparationProcess !== '<p></p>\n') &&

                        <View style={styles.row}>
                            <Text style={styles.underline}>Postup přípravy II: </Text>
                            <Text style={{marginHorizontal: 4, maxWidth: styles.text.maxWidth}}>
                                {removeHtmlTags(prescription.secondPreparationProcess)}
                            </Text>
                        </View>
                    }

                    <View style={styles.row}>
                        <Text style={styles.underline}>Obal I: </Text>
                        <Text style={styles.text}>{removeHtmlTags(prescription.container)}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Doba použitelnosti I: </Text>
                        <Text style={styles.text}>{convertExpiracyToCzech(prescription.expirationFirst)}</Text>
                    </View>

                    {
                        prescription.secondContainer !== null && prescription.secondContainer !== '' &&
                        <View style={styles.row}>
                            <Text style={styles.underline}>Obal II: </Text>
                            <Text style={styles.text}>{removeHtmlTags(prescription.secondContainer)}</Text>
                        </View>
                    }

                    {
                        prescription.expirationSecond !== null && prescription.expirationSecond !== '' &&
                        <View style={styles.row}>
                            <Text style={styles.underline}>Doba použitelnosti II: </Text>
                            <Text style={styles.text}>{convertExpiracyToCzech(prescription.expirationSecond)}</Text>
                        </View>
                    }

                    <View style={styles.row}>
                        <Text style={styles.underline}>Označení: </Text>
                        <Text style={styles.text}>{convertColorToCzech(prescription.labelColor)}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Podmínky uchovávání: </Text>
                        <Text style={styles.text}>{removeHtmlTags(prescription.storageConditions)}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Kontrola / vzhled: </Text>
                        <Text style={styles.text}>{removeHtmlTags(prescription.control)}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Zdroj receptury: </Text>
                        <Text style={styles.text}>{removeHtmlTags(prescription.source)}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.underline}>Poznámka: </Text>
                        <Text style={styles.text}>{removeHtmlTags(prescription.comment)}</Text>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tr}>
                        <Text style={styles.nameTd}>Zapsal: {removeHtmlTags(writtenBy)}</Text>
                        <Text style={styles.td}>Datum: {removeHtmlTags(writtenDate)}</Text>
                        <Text style={styles.td}>Podpis: </Text>

                    </View>

                    <View style={styles.tr}>
                        <Text style={styles.nameTd}>Schválil: {removeHtmlTags(approvedBy)}</Text>
                        <Text style={styles.td}>Datum: {removeHtmlTags(approvedDate)}</Text>
                        <Text style={styles.td}>Podpis: </Text>
                    </View>

                    <View style={styles.trNoBorder}>
                        <Text style={styles.note}>Poznámka: {removeHtmlTags(note)}</Text>
                    </View>
                </View>

            </Page>
        </Document>
    );
}

export default ExportTemplate;