import {Card} from "react-bootstrap";

export default function LabelColorButton({selected, color, onClickHandler}){
    return <Card style={{
        width: "8rem", cursor: "pointer",
    }}
    className={'d-inline-block text-center me-1 mb-1 cursor-pointer ' + (color === "red" ? ' bg-danger text-light' : '') +
    (selected === color && selected === "white" ? ' border-primary border-3' : '') +
    (selected === color && selected === "red" ? ' border-primary border-4' : '')
    }
    onClick={() => onClickHandler()}
>
<Card.Body>
    {
        color === "red" ? (
            <span>Červená signatura</span>
        ) : color === "white" ? (
            <span>Bílá signatura</span>
        ) : null
    }
    </Card.Body>
</Card>
}