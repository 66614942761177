export const CONF_CONTAINERS = [
    'Kelímek plast',
    'Kelímek typu Unguator®',
    'Lékovka hnědá sklo',
    'Lékovka hnědá s pipetou',
    'Lékovka hnědá s kapací vložkou',
    'Lékovka hnědá se štětečkem',
    'Lékovka hnědá s nosním aplikátorem',
    'Lékovka s rozprašovačem',
    'Lékovka širokohrdlá sklo',
    'Lékovka SANO s kapátkem  sterilní + skládačka papír',
    'Bralenka sterilní + skládačka papír',
    'Tuba ALU',
    'Bralenka',
    'Forma na čípky plast + skládačka papír',
    'Celofán/alobal + kelímek plast',
    'Kelímek/Doza tbl plast/Lékovka širokohrdlá',
    'Volný text...'
];