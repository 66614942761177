import {getAuthInfo} from "../../../utils/auth";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import {AppContext} from "../../../App";
import axios from "axios";
import {apiUrl} from "../../../utils/config";
import {Button} from "react-bootstrap";

export default function TopBar(props) {
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;
    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState(searchParams.get('searchText') ? searchParams.get('searchText') : '');
    

    const logOut = () => {
        axios(`${apiUrl}/api/user/logOut`, {
            headers: {
                Authorization: context.token
            }
        }).then(
            context.logout()
        ).catch()
    }

    const authInfo = getAuthInfo();

    const handleSearch = () =>{
        navigate(`/prescription?searchText=${searchText}`,{ replace: true });
    }

    return <nav className="navbar navbar-expand sticky-top navbar-light bg-white topbar mb-4 static-top shadow">
        <form className="form-inline m-3 pt-3 d-md-none">

                <Link to={"/prescription"} style={{
                    textDecoration: "none",
                }}
                      className="text-center main-color"
                >
                    <i className="fas fa-pills"></i>
                    <p className="small">IPLP</p>
                </Link>

        </form>

        <form
            className="form-inline mr-auto ml-md-3 my-2 my-md-0 navbar-search ">
            <div className="input-group w-100">
                <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Hledat..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    value={searchText}
                    onKeyDown={(e)=>{
                        if(e.key === 'Enter'){
                            e.preventDefault();
                            handleSearch();
                        }
                    }}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        if(props.searchTextHandler){
                            props.searchTextHandler(e.target.value)
                        }
                    }}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSearch}
                    >
                        <i className="fas fa-search fa-sm"></i>
                    </button>
                </div>
            </div>
        </form>

        <ul className="navbar-nav ml-auto">

            <li className="nav-item dropdown no-arrow">
                {
                    authInfo !== null &&
                    <div className="d-flex flex-row">

                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="userDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                        >
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small" style={{
                        fontSize: "1em"
                    }}>
                {authInfo.user.email}
                    </span>
                            <i className="bi bi-file-person" style={{
                                fontSize: "2em"
                            }}></i>
                        </a>

                        <Button className="my-3" onClick={() => logOut()}>
                            <i className="bi bi-box-arrow-left p-1"/>
                            Odhlásit se
                        </Button>
                    </div>
                }

                {/* Client don't want to show Sing up button at this place
                        <Link to="/login">
                            <Button>Přihlášení</Button>
                        </Link>
                        */}


                <div
                    className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                >
                    <a className="dropdown-item" href="#">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                    </a>
                    <a className="dropdown-item" href="#">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                    </a>
                    <a className="dropdown-item" href="#">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                        Activity Log
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                        className="dropdown-item"
                        href="#"
                        data-toggle="modal"
                        data-target="#logoutModal"
                    >
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </nav>;

}