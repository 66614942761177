import {Modal, Button, ModalBody} from "react-bootstrap";
import {useState} from "react";

function userName(user) {
    let name = user.titleBefore ? (user.titleBefore + " ") : '';
    name += (user.firstName + " " + user.lastName);
    name += user.titleAfter ? user.titleAfter : '';
    return name;
}

export default function CommentModal({
                                         show,
                                         handleClose,
                                         handleSuccess,
                                         handleInput,
                                         inputValue,
                                         comments
                                     }) {
    const [showRequired, setShowRequired] = useState(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Změnový komentář</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                maxHeight: '400px',
                overflow: 'auto',
            }}>
                {comments.map((comment) => {
                    return (
                        <div style={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '8px 8px',
                            margin: '0 12px 3px 12px',
                        }}
                        key={comment.id}>
                            <p>{comment.comment}</p>
                            <div className='d-flex flex-row justify-content-between'>
                                <span>{comment.User.name}</span>
                                <span>{comment.createdAt}</span>
                            </div>
                        </div>
                    );
                })}
            </Modal.Body>
            <Modal.Body>
                <div>
                    Popište změnu, kterou jste udělali
                </div>
                <div>
                    <textarea
                        rows={'3'}
                        style={{
                            borderColor: '#ccc',
                            borderRadius: '6px',
                            width: '100%',
                        }}
                        onChange={(e) => handleInput(e)}
                        value={inputValue}
                    />
                    {
                        showRequired && (
                            <span style={{
                                color: '#8e0000',
                            }}>Je nutné vyplnit popis změny!</span>
                        )
                    }

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => {
                        if (inputValue === null || inputValue.match(/^ *$/) !== null) {
                            setShowRequired(true);
                        } else {
                            handleSuccess();
                            setShowRequired(false);
                        }
                    }}
                >
                    Uložit změnu receptu
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    Vrátit se k úpravám
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
