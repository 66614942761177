import {Button, Col, Image, Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../../utils/img/lekarnicka-komora.png"
import logo2 from "../../utils/img/logo-ipvz.jpg"
import {useContext, useState} from "react";
import {AppContext} from "../../App";

export default function Home() {
    const context = useContext(AppContext);
    const [modalShow, setModalShow] = useState(context.agreed ? false : true);

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Souhlas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tyto webové stránky, jsou určeny výhradně odborníkům dle zákona č. 40/1995 Sb. o regulaci reklamy ve znění pozdějších předpisů.</p>

                    <p>Odborník ve smyslu §2a zákona číslo 40/1995 Sb., je osoba oprávněná předepisovat nebo vydávat léčivé přípravky či zdravotnické prostředky nebo osoba oprávněná poskytovat zdravotní péči. Pokud osoba, která odborníkem není, vstoupí na tyto webové stránky určené odborníkům, riskuje tím nesprávné porozumění obsahu těchto stránek a z toho plynoucí rizika.</p>

                    <p>Pokud budete pokračovat na tyto stránky, prohlašujete, že jste:</p>

                    <ul>
                        <li>odborníkem ve smyslu zákona číslo 40/1995 Sb.</li>
                        <li>se seznámil/a s definicí pojmu "odborník" dle zákona číslo 40/1995 Sb.</li>
                        <li>se seznámil/a s riziky, kterým se jiná osoba než odborník vystavuje, jestliže vstoupí na stránky určené převáženě pro odborníky</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button onClick={props.onDecline} variant="danger" className="">Nesouhlasím</Button>*/}
                    <Button onClick={props.onSuccess}>Potvrzuji</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="App">
            <header>

                <div className="container pt-3">

                    {
                        context.agreed ? (

                            <div className="row mt-3 pt-3 text-center">

                                <div className="name-box" >
                                    <h1>Elektronický receptář individuálně připravovaných léčivých přípravků (IPLP)</h1>
                                </div>

                                <Col>
                                    <Link to={'/prescription'} className="card bg-drg text-white mt-5">
                                        <div className="card-body">
                                            Farmaceut
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to={'/prescription'} className="card bg-drg text-white mt-5">
                                        <div className="card-body">
                                            Lékař
                                        </div>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to={'/login'} className="card bg-drg text-white mt-5">
                                        <div className="card-body">
                                            Admin
                                        </div>
                                    </Link>
                                </Col>

                            </div>
                        ) : (
                            <div className="mt-3 pt-5 pb-5"></div>
                        )
                    }

                    <div className="p-3 copyright-box">
                        <div className="row">
                            <div className="col-12 col-md-4">

                                <div className="row">
                                    <div className="col-9 p-3">
                                        <a href="https://www.lekarnici.cz/" target="_blank">
                                            <Image className="logo-fix-size" src={logo} alt="Česká lékárnická komora logo"></Image>
                                        </a>
                                    </div>
                                    <div className="col-3 p-3">
                                        <a href="https://www.ipvz.cz/" target="_blank">
                                            <Image className="logo-fix-size" src={logo2} alt="Institut postgraduálního vzdělávání logo"></Image>
                                        </a>
                                    </div>
                                </div>

                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onSuccess={() => {
                                        context.agree();
                                        setModalShow(false);
                                    }}
                                    onDecline={() => {
                                        context.decline();
                                        setModalShow(false);
                                    }}
                                />

                            </div>

                            <div className="col-12 col-md-4 offset-md-4">
                                <div className="row">
                                    <div className="col-12 col-md-6 offset-md-6 p-3">
                                        <p>
                                            Email:
                                            <a href="mailto:iplprecept@lekarnici.cz"> iplprecept@lekarnici.cz</a>
                                        </p>

                                        <p>© Česká lékárnická komora 2022</p>
                                        <p>made by futudrg team</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>


    );
}