const LOCAL_STORAGE_AGREEMENT_KEY = 'agreement';

export const getAgreementInfo = () => {
    if (!window.localStorage) {
        return null;
    }

    try {
        return window.localStorage.getItem(LOCAL_STORAGE_AGREEMENT_KEY);
    } catch (err) {
        return null;
    }
}

export const setAgreementInfo = () => {
    window.localStorage.setItem(LOCAL_STORAGE_AGREEMENT_KEY, "1");
}

export const deleteAgreementInfo = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_AGREEMENT_KEY);
}