import React, {useContext} from "react";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import TopBar from "../../../components/menu/topbar/TopBar";
import ComponentForRole from "../../../components/ComponentForRole";
import PrescriptionSidebarPanel from "../../../components/menu/sidebar/PrescriptionSidebarPanel";
import {apiUrl} from "../../../utils/config";
import {AppContext} from "../../../App";
import {ReactPDF, PDFDownloadLink} from "@react-pdf/renderer";
import ExportTemplate from "./ExportTemplate";

export default function PrescriptionExport(props) {
    let params = useParams();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;

    const [prescription, setPrescription] = useState(null);
    const [pharmaName, setPharmaName] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [approvedDate, setApprovedDate] = useState('');
    const [writtenBy, setWrittenBy] = useState("");
    const [writtenDate, setWrittenDate] = useState('');
    const [validFrom, setValidFrom] = useState("");
    const [note, setNote] = useState("");
    const [showDownloading, setShowDownloading] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    useEffect(() => {
        axios
            .get(`${apiUrl}/api/prescriptions/${params.slug}`, {
                headers: {
                    Authorization: context.token
                }
            })
            .then((response) => {
                // console.debug(response);
                if (response.status == 200) {
                    setPrescription(response.data);
                    // console.debug(response.data)
                    // console.debug(prescription)
                }
            });
        // console.debug("fetching data");
        // axios.get('http://localhost:4000/api/export');
        // console.debug(params.id);
        // setTimeout(function () {
        //
        // }, 500);

        //TODO - error handling
    }, []);

    const navigation = useNavigate();

    const onSubmitHandler = (e) => {
        e.preventDefault();

        // return  navigation('/prescription/lorem/exportResult', )

        setShowDownloading(true);
        axios
            .get(`${apiUrl}/api/export/${params.slug}`, {
                params: {
                    name: pharmaName,
                    approvedBy: approvedBy,
                    approvedDate: approvedDate,
                    writtenBy: writtenBy,
                    writtenDate: writtenDate,
                    note: note
                },
                responseType: 'blob'
            })
            .then(response => URL.createObjectURL(response.data))
            .then(uril => {
                const link = document.createElement("a");
                link.href = uril;
                link.download = `${params.slug}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).then(()=>{
                setShowDownloading(false);
        })
            .catch((err) => {
                //TODO show erro modal when incomplete
                console.debug(err);
            })
    }

    return (
        <>
            <div id="wrapper">
                <Sidebar>
                    {prescription && (
                        <ComponentForRole role={role} component={
                            <PrescriptionSidebarPanel slug={prescription.slug}
                                                      state={prescription.state}
                                                      name={prescription.name}
                                                      />
                        }/>
                    )}
                </Sidebar>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar role={'admin'}/>

                        {prescription ? (
                            <Container>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název IPLP
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Název nedostupného HVLP
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                {prescription.UnavailableDrugs.map((drug) => {
                                                    return <span
                                                        className={'me-1 badge bg-secondary fs-6'}>{drug.name}</span>;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="card mb-4">
                                    <div className="card-header py-3">
                                        <h3>Hlavička exportu</h3>
                                    </div>

                                    <div className="card-body">
                                        <Form onSubmit={(e) => onSubmitHandler(e)}>
                                            <Row className={"mb-3"}>
                                                <Col>
                                                    <Form.Label>Název lékárny / instituce</Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={pharmaName}
                                                        onChange={(e) => {
                                                            setPharmaName(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>Zapsal</Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={writtenBy}
                                                        onChange={(e) => {
                                                            setWrittenBy(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Datum</Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={writtenDate}
                                                        onChange={(e) => {
                                                            setWrittenDate(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>Schválil</Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={approvedBy}
                                                        onChange={(e) => {
                                                            setApprovedBy(e.target.value);
                                                        }}
                                                    />
                                                </Col>

                                                <Col>
                                                    <Form.Label>
                                                        Datum
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={approvedDate}
                                                        onChange={(e) => {
                                                            setApprovedDate(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>Poznámka</Form.Label>

                                                    <Form.Control
                                                        type="string"
                                                        value={note}
                                                        onChange={(e) => {
                                                            setNote(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    {
                                                        showDownloading ? (
                                                            <div>
                                                                <Spinner animation="border m-3"/>
                                                            </div>
                                                        ) : (
                                                        <PDFDownloadLink
                                                            document=
                                                            {<ExportTemplate
                                                                pharmaName={pharmaName}
                                                                approvedBy={approvedBy}
                                                                approvedDate={approvedDate}
                                                                writtenBy={writtenBy}
                                                                writtenDate={writtenDate}
                                                                note={note}
                                                                prescription={prescription}
                                                            />}
                                                            fileName={`${params.slug}.pdf`}
                                                        >
                                                            <Button>
                                                                <i className="bi bi-file-earmark-pdf-fill mr-2" />
                                                                Stáhnout export
                                                            </Button>
                                                        </PDFDownloadLink>
                                                        )
                                                    }

                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Container>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <Spinner animation="grow"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
