import Table from "react-bootstrap/Table";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {dateFormatted} from "../../../components/Formater";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import TopBar from "../../../components/menu/topbar/TopBar";
import {AppContext} from "../../../App";
import StateFlag from "../../../components/StateFlag";
import {apiUrl} from "../../../utils/config";

export default function UserList(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;
    //TOTO - replace with true after API gateway will be completed
    const [loading, setLoading] = useState(false);
    //TOTO - replace with [] after API gateway will be completed
    const [users, setUsers] = useState([
        {
            name: 'Ing. Milan Milanovisky',
            createdAt: Date.now().toString(),
        },
        {
            name: 'Mgr. Pavol Petrovic',
            createdAt: Date.now().toString(),
        }

    ]);
    const [approveModalShow, setApproveModalShow] = useState(false);
    const [toApprovalModalShow, setToApprovalModalShow] = useState(false);
    const [toConceptModalShow, setToConceptModalShow] = useState(false);
    const [changeStateId, setChangeStateId] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [order, setOrder] = useState(null);
    const [conceptsShow, setConceptsShow] = useState(true);
    const [toApproveShow, setToApproveShow] = useState(true);


    const getAllUsers = () => {
        axios
            .get(`${apiUrl}/api/users`)
            .then((response) => {
                console.debug(response.data);
                setUsers(response.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        //TOTO - uncomment after API gateway will be completed
        // getAllUsers();
    }, [timestamp]);

    return (
        <>
            <div id="wrapper">

                <Sidebar/>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar role={role}/>
                        <div className="row mb-4">
                            <div className="col ml-4">
                                <Link
                                    to={`create`}
                                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2"
                                >
                                    <i className="bi bi-person-plus"></i>{" "}
                                    Přidať uživatele
                                </Link>
                            </div>
                        </div>

                        {
                            loading ? (
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <Spinner animation="grow"/>
                                </div>
                            ) : (
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            Jméno
                                        </th>
                                        <th>
                                            Vytvořen
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((item, i) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    <span>{i + 1}</span>
                                                </td>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {dateFormatted(item.createdAt)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
}
