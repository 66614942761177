import {Modal} from "react-bootstrap";

export default function FormFailModal({show}) {
    return (
        <Modal
            centered
            show={show}
        >
            <Modal.Body className={"d-flex justify-content-evenly align-items-center"}>
                            <span className={'text-danger'} style={{
                                fontSize: '5em',
                            }}>
                                <i className="bi bi-x-circle-fill"></i>
                            </span>
                <span className={'ml-3 text-danger'} style={{
                    fontSize: '1.5em',
                }}>
                                Zkontrolujte položky formuláře
                            </span>
            </Modal.Body>
        </Modal>
    );
}