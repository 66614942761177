import { Editor } from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {useState} from "react";
import './Texteditor.css';
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

export function getEditorStateFromHTML(data){
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
}

export function getEditorDataAsHTML(editorState){
    return draftToHtml(convertToRaw(editorState.getCurrentContent()))
}

export function TextEditor({editorState, onEditorStateChangeHandler, wrapperClass = "editor-wrapper"}){

    return (<Editor
        editorState={editorState}
        wrapperClassName={wrapperClass}
        toolbarClassName="editor-toolbar"
        editorClassName="editor-editor"
        onEditorStateChange={ (s) => {
            onEditorStateChangeHandler(s);
        }}
        toolbar={{
            options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'colorPicker', 'remove', 'history'],
            inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            colorPicker: {
                popupClassName: 'color-picker-popup',
                className: 'color-picker-btn'
            },
            link: {
                inDropdown: false,
                showOpenOptionOnHover: true,
              },
        }}
    />);
}

export function PoorLinkEditor({editorState, onEditorStateChangeHandler, wrapperClass = "editor-wrapper-smallest"}){

    return (<Editor
        editorState={editorState}
        wrapperClassName={wrapperClass}
        toolbarClassName="editor-toolbar"
        editorClassName="editor-editor"
        onEditorStateChange={ (s) => {
            onEditorStateChangeHandler(s);
        }}
        toolbar={{
            options: ['inline', 'link'],
            inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            link: {
                inDropdown: false,
                showOpenOptionOnHover: true,
              },
        }}
    />);
}