import {Button, Col, Container, Form, Row, Spinner, FormCheck} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import SuccessModal from "../../../components/modals/SuccessModal"
import FailModal from "../../../components/modals/FailModal";
import Sidebar from "../../../components/menu/sidebar/Sidebar";
import {useNavigate, useParams} from "react-router-dom";
import LabelColorButton from "../../../components/LabelColorButton";
import {getEditorDataAsHTML, getEditorStateFromHTML, TextEditor, PoorLinkEditor} from "../../../components/editor/TextEditor";
import {EditorState} from "draft-js";
import ButtonWithTooltip from "../../../components/ButtonWithTooltip";
import CommentModal from "../../../components/modals/CommentModal";
import ComponentForRole from "../../../components/ComponentForRole";
import PrescriptionSidebarPanel from "../../../components/menu/sidebar/PrescriptionSidebarPanel";
import StateFlag from "../../../components/StateFlag";
import {apiUrl} from "../../../utils/config";
import TopBar from "../../../components/menu/topbar/TopBar";
import {AppContext} from "../../../App";
import {ErrorMessageForInput} from "../../../components/form/ErrorMessageForInput";
import InsurancePaymentButton from "../../../components/InsurancePaymentButton";
import {validatePrescriptionForm} from "../../../components/prescriptionValidation";
import FormFailModal from "../../../components/modals/FormFailModal";
import TextInputWithWhisperer from "../../../components/form/TextInputWithWhisperer";
import { CONF_SOURCES } from "../../../config/zdroj";
import { CONF_CONTROL } from "../../../config/kontrola-vzhled";
import { CONF_CONDITIONS } from "../../../config/podminky-uchovani";
import { CONF_WARNING_PATIENT } from "../../../config/upozorneni-pacient";
import { CONF_EXPIRATION } from "../../../config/expirace";
import { CONF_CONTAINERS } from "../../../config/nadoby";

export default function PrescriptionEdit() {
    let params = useParams();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const role = context.user ? context.user.role : null;

    const [name, setName] = useState("");
    const [oldName, setOldName] = useState("");
    const [sign, setSign] = useState(null);

    const [ingredients, setIngredients] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedSecondType, setSelectedSecondType] = useState(null);
    /* needs for render */
    const [indications, setIndications] = useState(null);
    const [dosageForms, setDosageForms] = useState(null);
    const [processTypes, setProcessTypes] = useState(null);
    const [safetyProcesses, setSafetyProcesses] = useState(null);
    const [prescriptionLoaded, setPrescriptionLoaded] = useState(false);
    /* --- */
    const [unavailableDrugs, setUnavailableDrugs] = useState([]);
    const [comments, setComments] = useState([]);
    const [doctorNotes, setDoctorNotes] = useState("");
    const [pharmacistNotes, setPharmacistNotes] = useState("");
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedIndication, setSelectedIndication] = useState(null);
    const [selectedContainer, setSelectedContainer] = useState(null);
    const [selectedSecondaryContainer, setSelectedSecondaryContainer] = useState(null);
    const [selectedColor, setSelectedColor] = useState("");
    const [contact, setContact] = useState("");
    const [expirationFirst, setExpirationFirst] = useState("");
    const [expirationSecond, setExpirationSecond] = useState("");
    const [noticeInsurancePayment, setNoticeInsurancePayment] = useState("")

    const [ingredientsWhispers, setIngredientsWhispers] = useState([])
    const [ingredientSearchText, setIngredientSearchText] = useState("");
    const [freeTextSale, setFreeTextSale] = useState("");

    const [noticeForPatient, setNoticeForPatient] = useState("");
    const [control, setControl] = useState("");
    const [storageConditions, setStorageConditions] = useState("");
    const [insurancePayment, setInsurancePayment] = useState(null);
    const [overToCounter, setOverToCounter] = useState(null);

    const [successModalShow, setSuccessModalShow] = useState(false);
    const [failModalShow, setFailModalShow] = useState(false);
    const [loading, setLoading] = useState(0);
    const [editComment, setEditComment] = useState("");
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [failFormModalShow, setFailFormModalShow] = useState(false);

    const [showMaterials, setShowMaterials] = useState(false);

    const [stateFlag, setStateFlag] = useState(null);


    //STATE FOR WYSIWYG EDITOR
    const [safetyEditorState, setSafetyEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [oldProcessText, setOldProcessText] = useState(null);
    const [secondEditorState, setSecondEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [commentEditorState, setCommentEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [sourceEditorState, setSourceEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [whisperSource, setWhisperSource] = useState(false);

    const [oldProcessSecondText, setOldProcessSecondText] = useState(null);
    const [oldContainer, setOldContainer] = useState(null);
    const [oldExpirationFirst, setOldExpirationFirst] = useState('');
    const [oldSecondContainer, setOldSecondContainer] = useState(null);
    const [oldExpirationSecond, setOldExpirationSecond] = useState('');

    const [staticDataReady, setStaticDataReady] = useState(false);

    useEffect(() => {
        axios.get(`${apiUrl}/api/safety_process`)
            .then((response) => {
                console.debug(response)
                setSafetyProcesses(response.data)
                setLoading((old) => {
                    return old + 1;
                });
            })
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/api/preparation_process`)
            .then((response) => {
                console.debug(response)
                setProcessTypes(response.data)
                setLoading((old) => {
                    return old + 1;
                });
            })
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/api/indication`)
            .then((response) => {
                console.debug(response)
                setIndications(response.data)
                setLoading((old) => {
                    return old + 1;
                });
            })
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/api/dosage_form`)
            .then((response) => {
                console.debug(response)
                setDosageForms(response.data)
                setLoading((old) => {
                    return old + 1;
                });
            })
    }, [])

    useEffect(() => {
        let searchText = '';
        if (ingredientSearchText.length > 1) {
            searchText = "?searchText=" + ingredientSearchText;
        }
        axios.get(`${apiUrl}/api/ingredient${searchText}`)
            .then((response) => {
                console.debug(response)
                if (response.status === 200) {
                    setIngredientsWhispers(response.data)
                }
            }).catch(err => {
        })
    }, [ingredientSearchText])

    useEffect(() => {
        axios.get(`${apiUrl}/api/prescriptions/${params.slug}`, {
            headers: {
                Authorization: context.token
            }
        })
            .then((response) => {
                console.debug(response.data)
                setName(response.data.name);
                setOldName(response.data.name);
                if(response.data.sign != null){
                    setSign(response.data.sign);
                }
                setUnavailableDrugs(response.data.UnavailableDrugs);
                setSelectedForm(response.data.DosageFormId);
                setSelectedIndication(response.data.IndicationId);
                setIngredients(response.data.Ingredients);
                setMaterials(response.data.Substances);
                if (response.data.Substances.length > 0) {
                    setShowMaterials(true);
                }
                setFreeTextSale(response.data.freeTextSale)
                setSelectedColor(response.data.labelColor);
                if (response.data.preparationProcess) setEditorState(getEditorStateFromHTML(response.data.preparationProcess));
                if (response.data.secondPreparationProcess) setSecondEditorState(getEditorStateFromHTML(response.data.secondPreparationProcess));
                if (response.data.safetyPreparationProcess) setSafetyEditorState(getEditorStateFromHTML(response.data.safetyPreparationProcess));
                setOldProcessText(response.data.preparationProcess);
                setOldProcessSecondText(response.data.secondPreparationProcess);
                if (response.data.PrescriptionChanges) setComments(response.data.PrescriptionChanges);
                if (response.data.source) setSourceEditorState(getEditorStateFromHTML(response.data.source));
                if (response.data.comment) setCommentEditorState(getEditorStateFromHTML(response.data.comment));
                setContact(response.data.contact);
                setExpirationFirst(response.data.expirationFirst);
                setOldExpirationFirst(response.data.expirationFirst);
                setExpirationSecond(response.data.expirationSecond);
                setOldExpirationSecond(response.data.expirationSecond);
                setNoticeForPatient(response.data.noticeForPatient);
                setControl(response.data.control);
                setStorageConditions(response.data.storageConditions);
                setInsurancePayment(response.data.insurancePayment);
                if (response.data.overToCounter !== undefined) setOverToCounter(response.data.overToCounter);
                setDoctorNotes(response.data.doctorNotes);
                setPharmacistNotes(response.data.pharmacistNotes);
                setNoticeInsurancePayment(response.data.noticeInsurancePayment);
                setStateFlag(response.data.state);
                setSelectedContainer(response.data.container);
                setOldContainer(response.data.container);
                setSelectedSecondaryContainer(response.data.secondContainer);
                setOldSecondContainer(response.data.secondContainer);
                setLoading((old) => {
                    return old + 1;
                });
                setPrescriptionLoaded(true);
            })
    }, [staticDataReady])

    useEffect(() => {
        if (loading === 3) setStaticDataReady(true);
    }, [loading])

    const reloadOldProcessState = () => {
        setEditorState(getEditorStateFromHTML(oldProcessText));
        setSelectedType(null);
    }
    const reloadOldProcessSecondState = () => {
        setSecondEditorState(getEditorStateFromHTML(oldProcessSecondText));
        setSelectedSecondType(null);
    }

    const changeIngredientNameHandler = (i, e) => {
        setIngredientSearchText(e.target.value);

        let newIngredients = [...ingredients];
        let updatedIngredient = {...newIngredients[i]};
        updatedIngredient.name = e.target.value;
        newIngredients[i] = updatedIngredient;
        setIngredients(newIngredients);

        console.debug("changeNameHandler");
        console.debug(ingredients);
    };

    const changeIngredientAmountHandler = (i, e) => {
        let newIngredients = [...ingredients];
        let updatedIngredient = {...newIngredients[i]};
        updatedIngredient.amount = e.target.value;
        newIngredients[i] = updatedIngredient;
        setIngredients(newIngredients);

        console.debug("changeIngredientAmountHandler");
        console.log(ingredients);
    };

    const changeMaterialNameHandler = (i, e) => {
        let newMaterials = [...materials];
        let updatedMaterial = {...newMaterials[i]};
        updatedMaterial.name = e.target.value;
        newMaterials[i] = updatedMaterial;
        setMaterials(newMaterials);

        console.log("changeMaterialNameHandler");
        console.log(newMaterials);
    };

    const changeMaterialAmountHandler = (i, e) => {
        let newMaterials = [...materials];
        let updatedMaterial = {...newMaterials[i]};
        updatedMaterial.amount = e.target.value;
        newMaterials[i] = updatedMaterial;
        setMaterials(newMaterials);

        console.log("changeMaterialAmountHandler");
        console.log(ingredients);
    };

    const deleteIngredientHandler = (i) => {
        let updatedIngredients = [...ingredients];
        updatedIngredients.splice(i, 1);
        setIngredients(updatedIngredients);

        console.log("deleteIngredientHandler");
        console.log(ingredients);
    };

    const addIngredientHandler = () => {
        setIngredients((old) => {
            return [...old, {name: "", amount: ""}];
        });
        console.log("addIngredientHandler");
        console.log(ingredients);
    };

    const deleteMaterialHandler = (i) => {
        let updatedMaterials = [...materials];
        updatedMaterials.splice(i, 1);
        setMaterials(updatedMaterials);

        console.log("deleteMaterialHandler");
        console.log(materials);
    };

    const addMaterialHandler = () => {
        setMaterials((old) => {
            return [...old, {name: "", amount: ""}];
        });
        console.log("addMaterialHandler");
        console.log(materials);
    };

    const addUnavailableDrugHandler = () => {
        setUnavailableDrugs((old) => {
            return [...old, {name: ""}];
        });
        console.log("addUnavailableDrugHandler");
        console.log(unavailableDrugs);
    };

    const changeUnavailableDrugHandler = (i, e) => {
        let newUnavailableDrugs = [...unavailableDrugs];
        let updatedUnavailableDrug = {...newUnavailableDrugs[i]};
        updatedUnavailableDrug.name = e.target.value;
        newUnavailableDrugs[i] = updatedUnavailableDrug;
        setUnavailableDrugs(newUnavailableDrugs);

        console.log("changeUnavailableDrugHandler");
        console.log(unavailableDrugs);
    };

    const deleteUnavailableDrugHandler = (i) => {
        let updatedUnavailableDrugs = [...unavailableDrugs];
        updatedUnavailableDrugs.splice(i, 1);
        setUnavailableDrugs(updatedUnavailableDrugs);

        console.log("deleteUnavailableDrugHandler");
        console.log(unavailableDrugs);
    };

    const submitFormHandler = (e) => {
        e.preventDefault();
        if (validatePrescriptionForm({
            name: name,
            selectedColor: selectedColor,
            expirationFirst: expirationFirst,
            expirationSecond: expirationSecond,
            unavailableDrugs: unavailableDrugs,
            ingredients: ingredients,
            materials: materials,
            selectedForm: selectedForm,
            insurancePayment: insurancePayment,
            selectedIndication: selectedIndication,
            selectedContainer: selectedContainer,
            selectedSecondaryContainer: selectedSecondaryContainer,
        }, setShowErrors)) {
            setShowCommentModal(true);
        } else {
            setShowErrors(true);
            setFailFormModalShow(true);
            setTimeout(function () {
                setFailFormModalShow(false)
            }, 1500);
        }
    }

    const sendDataHandler = () => {
        const dataObject = {
            name: name,
            sign: sign,
            unavailableDrugs: [...unavailableDrugs],
            DosageFormId: selectedForm,
            IndicationId: selectedIndication,
            container: selectedContainer,
            secondContainer: selectedSecondaryContainer,
            ingredients: [...ingredients],
            materials: [...materials],
            pharmacistNotes: pharmacistNotes,
            doctorNotes: doctorNotes,
            labelColor: selectedColor,
            processText: getEditorDataAsHTML(editorState),
            processTextSecond: getEditorDataAsHTML(secondEditorState),
            safetyPreparationProcess: getEditorDataAsHTML(safetyEditorState),
            PreparationProcessId: selectedType,
            editComment: editComment,
            source: getEditorDataAsHTML(sourceEditorState),
            comment: getEditorDataAsHTML(commentEditorState),
            contact: contact,
            noticeForPatient: noticeForPatient,
            control: control,
            storageConditions: storageConditions,
            insurancePayment: insurancePayment,
            expirationFirst: expirationFirst,
            expirationSecond: expirationSecond,
            noticeInsurancePayment: noticeInsurancePayment,
            overToCounter: overToCounter,
            freeTextSale: freeTextSale
        }
        console.log(dataObject);

        axios
            .put(`${apiUrl}/api/prescriptions/${params.slug}`, dataObject, {
                headers: {
                    Authorization: context.token
                }
            })
            .then((response) => {
                console.log(response);
                if (response.status === 204) {
                    setSuccessModalShow(true);
                    setTimeout(function () {
                        if (oldName === name) {
                            navigate(`../prescription/${params.slug}`, {replace: true});
                        } else {
                            navigate(`../prescription/`, {replace: true});
                        }

                    }, 1500);
                } else {
                    setFailModalShow(true);
                    setTimeout(function () {
                        setFailModalShow(false)
                    }, 1500);
                }
            }).catch(() => {
            setFailModalShow(true);
            setTimeout(function () {
                setFailModalShow(false)
            }, 1500);
        });
    };

    const selectTypeHandler = (id, editorSetter, selectSetter) => {
        selectSetter(id);
        let processText = processTypes.filter((processType) => {
            return processType.id === id;
        })
        if (processText) {
            editorSetter(getEditorStateFromHTML(processText[0].text))
        }
    }

    const addTextToEditorStateHandler = (id, texts, oldState, editorSetter) => {
        let textToAdd = texts.filter((text) => {
            return text.id === id;
        })
        if (textToAdd) {
            const oldText = getEditorDataAsHTML(oldState);
            if(oldText.includes("<p></p>") && oldText.length == 8){
                editorSetter(getEditorStateFromHTML(textToAdd[0].text))
            } else {
                editorSetter(getEditorStateFromHTML(oldText + "<br>" + textToAdd[0].text))
            }
        }
    }    

    return (
        <>
            <CommentModal show={showCommentModal}
                          handleClose={() => setShowCommentModal(false)}
                          handleInput={
                              (e) => {
                                  setEditComment(e.target.value);
                                  console.log(editComment);
                              }
                          }
                          comments={comments}
                          inputValue={editComment}
                          handleSuccess={() => {
                              sendDataHandler();
                              setShowCommentModal(false);
                              setEditComment('');
                          }}
            />
            <div id="wrapper">

                <Sidebar role={role}>
                    {loading && (
                        <>
                            <ComponentForRole role={role} component={
                                <PrescriptionSidebarPanel slug={params.slug}
                                                          state={stateFlag}
                                                          name={name}
                                />
                            }/>
                        </>
                    )}
                </Sidebar>

                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">

                        <TopBar/>

                        {
                            !(staticDataReady && prescriptionLoaded) ? (
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <Spinner animation="grow"/>
                                </div>
                            ) : (
                                <Container>

                                    <SuccessModal show={successModalShow}/>
                                    <FailModal show={failModalShow}/>
                                    <FormFailModal show={failFormModalShow}/>

                                    <div className="card mb-4">
                                        <div className="card-header py-3">
                                            <Row>
                                                <Col><h3>Úprava</h3></Col>
                                                <Col><StateFlag state={stateFlag}/></Col>
                                            </Row>
                                        </div>

                                        <div className="card-body">

                                            <Form onSubmit={submitFormHandler}>
                                                <Row className="border border-2 pt-3 rounded mb-3">
                                                    <Row className="mb-4">
                                                        <Col>
                                                            <Row>
                                                                <Form.Label>Název IPLP</Form.Label>
                                                                    <ErrorMessageForInput
                                                                        show={showErrors}
                                                                        required={true}
                                                                        string={true}
                                                                        input={name}
                                                                    >
                                                                        <Form.Control
                                                                            type="string"
                                                                            value={name}
                                                                            onChange={(e) => {
                                                                                setName(e.target.value);
                                                                            }}
                                                                        />
                                                                    </ErrorMessageForInput>
                                                            </Row>
                                                            <Row>
                                                                
                                                                <div>
                                                                    <Form.Check className="mr-3" inline type="radio" name="sign" value="" label="Bez značky" id="sign-0" 
                                                                        checked={sign === null}
                                                                        onChange={() => {setSign(null)}}
                                                                    />
                                                                    <Form.Check className="mr-3" inline type="radio" name="sign" value="1" label="Novinka" id="sign-1"
                                                                        checked={sign === 1}
                                                                        onChange={() => {setSign(1)}}
                                                                    />
                                                                    <Form.Check inline type="radio" name="sign" value="2" label="Změna" id="sign-2"
                                                                        checked={sign === 2}
                                                                        onChange={() => {setSign(2)}}
                                                                    /> 
                                                                </div>
                        
                                                            </Row>
                                                            
                                                        </Col>
                                                        <Col>
                                                            <Row className={"mb-3"}>
                                                                <Col>
                                                                    <Form.Label>Název nedostupného HVLP</Form.Label>

                                                                </Col>
                                                                <Col className={'d-flex justify-content-center'}>
                                                                    <ButtonWithTooltip
                                                                        iconName={'bi-plus'}
                                                                        onClickHandler={addUnavailableDrugHandler}
                                                                        tooltipText={'Přidej název nedostupného léku'}
                                                                        tooltipPlacement={'left'}
                                                                        keyProp={'unavailable-drug-btn'}
                                                                    />
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <ErrorMessageForInput
                                                                        show={showErrors}
                                                                        required={true}
                                                                        array={true}
                                                                        input={unavailableDrugs}
                                                                    >
                                                                        {
                                                                            unavailableDrugs && unavailableDrugs.map((item, i) => {
                                                                                return (
                                                                                    <Row key={item.id}
                                                                                         className="mb-1 d-flex align-items-center">
                                                                                        <Col>
                                                                                            <ErrorMessageForInput
                                                                                                show={showErrors}
                                                                                                required={true}
                                                                                                string={true}
                                                                                                input={item.name}
                                                                                            >
                                                                                                <Form.Control
                                                                                                    type="string"
                                                                                                    value={item.name}
                                                                                                    onChange={(e) => {
                                                                                                        changeUnavailableDrugHandler(
                                                                                                            i,
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </ErrorMessageForInput>
                                                                                        </Col>
                                                                                        <Col xs={1}>
                                                                                            <Button
                                                                                                style={{
                                                                                                    fontSize: '2em',
                                                                                                    padding: '0 0',
                                                                                                    lineHeight: '1.2em',
                                                                                                    background: 'inherit',
                                                                                                    border: '0',
                                                                                                    color: 'inherit',
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    deleteUnavailableDrugHandler(i)
                                                                                                }
                                                                                            >
                                                                                                <i className="bi bi-x"></i>
                                                                                            </Button>

                                                                                        </Col>
                                                                                    </Row>
                                                                                );
                                                                            })}
                                                                        {unavailableDrugs.length < 1 ?
                                                                            (
                                                                                <span
                                                                                    className="text-muted">Zatím nemáte žádny nedostatkový lék</span>
                                                                            ) : null
                                                                        }
                                                                    </ErrorMessageForInput>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row className="border border-2 pt-3 rounded mb-3">
                                                    <Row className="mb-4">
                                                        {
                                                            dosageForms && (
                                                                <Col>
                                                                    <Form.Label>Léková forma dle ČL</Form.Label>
                                                                    <Form.Select defaultValue={selectedForm}
                                                                                 onChange={(e) => {
                                                                                     setSelectedForm(e.target.value)
                                                                                 }}>
                                                                        {
                                                                            dosageForms.map((form, i) => {
                                                                                return <option key={form.id}
                                                                                               value={form.id}>
                                                                                    {form.name}
                                                                                </option>
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Col>
                                                            )
                                                        }
                                                        <Col>
                                                            <Form.Label>Indikační skupina</Form.Label>

                                                            <Form.Select defaultValue={selectedIndication}
                                                                         onChange={(e) => {
                                                                             setSelectedIndication(e.target.value)
                                                                         }}>
                                                                {
                                                                    indications && indications.map((indication, i) => {
                                                                        return <option key={indication.id}
                                                                                       value={indication.id}>
                                                                            {indication.name}
                                                                        </option>
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row className="mb-3 border border-2 pt-3 rounded">
                                                    <Row className="mb-3">
                                                        <Col xs={6}>
                                                            <Form.Label>Předpis pro lékaře - složení</Form.Label>
                                                        </Col>
                                                        <Col className={'d-flex justify-content-center'}>
                                                            <ButtonWithTooltip
                                                                iconName={'bi-plus'}
                                                                onClickHandler={addIngredientHandler}
                                                                tooltipText={'Přidej ingredienci'}
                                                                tooltipPlacement={'left'}
                                                                key={'ingredients-btn'}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            {/* NOT USEFUL NOWADAYS

                                                            <Form.Group>
                                                                <Form.Label>Poznámka pro lékaře</Form.Label>

                                                                <Form.Control
                                                                    type="string"
                                                                    value={doctorNotes}
                                                                    onChange={(e) => {
                                                                        setDoctorNotes(e.target.value);
                                                                    }}
                                                                />
                                                            </Form.Group>

                                                            */}
                                                        </Col>
                                                        <Col>
                                                            <ErrorMessageForInput
                                                                show={showErrors}
                                                                required={true}
                                                                array={true}
                                                                input={ingredients}
                                                            >
                                                                {
                                                                    ingredients.length < 1 ? null : (
                                                                        <Row>
                                                                            <Col xs={8}>
                                                                                <Form.Label className={"text-muted"}>Název
                                                                                    ingredience</Form.Label>
                                                                                <Row>
                                                                                    <Form.Label
                                                                                        className={"text-muted"}>Rp.</Form.Label>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col xs={2}>
                                                                                <Form.Label
                                                                                    className={"text-muted"}>Množství</Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }

                                                                {
                                                                    ingredients && ingredients.map((ingredient, i) => {
                                                                        return (
                                                                            <Row key={ingredient.id}
                                                                                 className="mb-1 d-flex align-items-center">
                                                                                <Col xs={8}>
                                                                                    <ErrorMessageForInput
                                                                                        show={showErrors}
                                                                                        required={true}
                                                                                        string={true}
                                                                                        input={ingredient.name}
                                                                                    >
                                                                                        <TextInputWithWhisperer
                                                                                            value={ingredient.name}
                                                                                            showOnChange={true}
                                                                                            onChangeHandlerEvent={(e) => {
                                                                                                changeIngredientNameHandler(
                                                                                                    i,
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            onWhispClickHandler={() => setIngredientSearchText("")}
                                                                                            whispers={ingredientsWhispers}
                                                                                        />
                                                                                    </ErrorMessageForInput>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <ErrorMessageForInput
                                                                                        show={showErrors}
                                                                                        required={false}
                                                                                        string={true}
                                                                                        input={ingredient.amount}
                                                                                    >
                                                                                        <Form.Control
                                                                                            type="string"
                                                                                            value={ingredient.amount}
                                                                                            onChange={(e) => {
                                                                                                changeIngredientAmountHandler(
                                                                                                    i,
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </ErrorMessageForInput>
                                                                                </Col>
                                                                                <Col xs={1}>
                                                                                    <Button
                                                                                        style={{
                                                                                            fontSize: '2em',
                                                                                            padding: '0 0',
                                                                                            lineHeight: '1.2em',
                                                                                            background: 'inherit',
                                                                                            border: '0',
                                                                                            color: 'inherit',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            deleteIngredientHandler(i)
                                                                                        }
                                                                                    >
                                                                                        <i className="bi bi-x"></i>
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    })}
                                                                {ingredients.length < 1 ?
                                                                    (
                                                                        <span
                                                                            className="text-muted">Zatím nemáte žádné ingredience</span>
                                                                    ) : null
                                                                }
                                                            </ErrorMessageForInput>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row className="mb-3 border border-2 pt-1 rounded">
                                                    <Row>
                                                        <Col className="d-flex align-items-center">
                                                            <div onClick={() => {
                                                                setShowMaterials(!showMaterials)
                                                            }}>
                                                                {
                                                                    showMaterials ? (
                                                                        <i className="bi bi-toggle-on" style={{
                                                                            fontSize: '2em',
                                                                            cursor: 'pointer'
                                                                        }}></i>
                                                                    ) : (
                                                                        <i className="bi bi-toggle-off" style={{
                                                                            fontSize: '2em',
                                                                            cursor: 'pointer'
                                                                        }}></i>
                                                                    )
                                                                }

                                                            </div>
                                                            <div className="ml-3 mt-1">
                                                                <Form.Label>Suroviny pro
                                                                    lékárníka</Form.Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {showMaterials && (
                                                        <>
                                                            <Row className="mb-3">
                                                                <Col xs={6}>
                                                                </Col>
                                                                <Col className={'d-flex justify-content-center'}>
                                                                    <ButtonWithTooltip
                                                                        iconName={'bi-plus'}
                                                                        onClickHandler={addMaterialHandler}
                                                                        tooltipText={'Přidej surovinu'}
                                                                        tooltipPlacement={'left'}
                                                                        key={'sustance-btn'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col>
                                                                    {/* NOT USEFUL NOWADAYS
                                                                    <Form.Group>
                                                                        <Form.Label>Poznámka pro lékárníka</Form.Label>

                                                                        <Form.Control
                                                                            type="string"
                                                                            value={pharmacistNotes}
                                                                            onChange={(e) => {
                                                                                setPharmacistNotes(e.target.value);
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                    */}
                                                                </Col>
                                                                <Col>
                                                                    {
                                                                        materials.length < 1 ? null : (
                                                                            <Row>
                                                                                <Col xs={8}>
                                                                                    <Form.Label
                                                                                        className={"text-muted"}>Název
                                                                                        suroviny</Form.Label>
                                                                                </Col>
                                                                                <Col xs={2}>
                                                                                    <Form.Label
                                                                                        className={"text-muted"}>Množství</Form.Label>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }

                                                                    {
                                                                        materials && materials.map((material, i) => {
                                                                            return (
                                                                                <Row key={material.id}
                                                                                     className="mb-1 d-flex align-items-center">
                                                                                    <Col xs={8}>
                                                                                        <ErrorMessageForInput
                                                                                            show={showErrors}
                                                                                            required={true}
                                                                                            string={true}
                                                                                            input={material.name}
                                                                                        >
                                                                                            <TextInputWithWhisperer
                                                                                                value={material.name}
                                                                                                showOnChange={true}
                                                                                                onChangeHandlerEvent={(e) => {
                                                                                                    changeMaterialNameHandler(
                                                                                                        i,
                                                                                                        e
                                                                                                    );
                                                                                                }}
                                                                                                // NOT VALID onWhispClickHandler={() => setIngredientSearchText("")}
                                                                                                // NOT VALID whispers={ingredientsWhispers}
                                                                                            />
                                                                                        </ErrorMessageForInput>
                                                                                    </Col>
                                                                                    <Col xs={2}>
                                                                                        <ErrorMessageForInput
                                                                                            show={showErrors}
                                                                                            required={false}
                                                                                            string={true}
                                                                                            input={material.amount}
                                                                                        >
                                                                                            <Form.Control
                                                                                                type="string"
                                                                                                value={material.amount}
                                                                                                onChange={(e) => {
                                                                                                    changeMaterialAmountHandler(
                                                                                                        i,
                                                                                                        e
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </ErrorMessageForInput>
                                                                                    </Col>
                                                                                    <Col xs={1}>
                                                                                        <Button
                                                                                            style={{
                                                                                                fontSize: '2em',
                                                                                                padding: '0 0',
                                                                                                lineHeight: '1.2em',
                                                                                                background: 'inherit',
                                                                                                border: '0',
                                                                                                color: 'inherit',
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                deleteMaterialHandler(i)
                                                                                            }
                                                                                        >
                                                                                            <i className="bi bi-x"></i>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            );
                                                                        })}
                                                                    {materials.length < 1 ?
                                                                        (
                                                                            <span
                                                                                className="text-muted">Zatím nemáte zvolené žádné suroviny</span>
                                                                        ) : null
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Row>

                                                <Row className="border border-2 pt-3 pb-3 rounded mb-3">
                                                    <Row>
                                                        <Col xs={2}>
                                                            <Form.Label>Postup přípravy I.</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col xs={11}>
                                                                    <Form.Group className={"d-flex flex-wrap"}>
                                                                        {processTypes && processTypes.map((type, i) => {
                                                                            return (
                                                                                <div style={{
                                                                                    width: '300px',
                                                                                }}
                                                                                     key={type.id}
                                                                                >
                                                                                    <Form.Check
                                                                                        label={type.name}
                                                                                        type="radio"
                                                                                        id={type.id}
                                                                                        key={type.id}
                                                                                        name="processType"
                                                                                        className="mb-2"
                                                                                        checked={
                                                                                            selectedType === type.id
                                                                                        }
                                                                                        onChange={() => {
                                                                                            selectTypeHandler(type.id, setEditorState, setSelectedType)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}

                                                                    </Form.Group>

                                                                </Col>
                                                                <Col xs={1}>
                                                                    {
                                                                        oldProcessText && (
                                                                            <ButtonWithTooltip
                                                                                iconName={'bi-arrow-repeat'}
                                                                                onClickHandler={reloadOldProcessState}
                                                                                tooltipText={'Obnovit původní text postupu přípravy'}
                                                                                tooltipPlacement={'left'}
                                                                                key={'repeat-btn-process'}
                                                                            />
                                                                        )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}></Col>
                                                        <Col>
                                                            <TextEditor
                                                                editorState={editorState}
                                                                onEditorStateChangeHandler={setEditorState}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-3">
                                                        <Col xs={2}>
                                                            <Form.Label>Postup přípravy II.</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col xs={11}>
                                                                    <Form.Group className={"d-flex flex-wrap"}>
                                                                        {processTypes && processTypes.map((type) => {
                                                                            return (
                                                                                <div style={{
                                                                                    width: '270px',
                                                                                }}
                                                                                     key={type.id + "-second"}
                                                                                >
                                                                                    <Form.Check
                                                                                        label={type.name}
                                                                                        type="radio"
                                                                                        id={type.id + "-second"}
                                                                                        key={type.id + "-second"}
                                                                                        name="processType-second"
                                                                                        className="mb-2"
                                                                                        checked={
                                                                                            selectedSecondType === type.id
                                                                                        }
                                                                                        onChange={() => {
                                                                                            selectTypeHandler(type.id, setSecondEditorState, setSelectedSecondType)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={1}>
                                                                    {
                                                                        oldProcessSecondText && (
                                                                            <ButtonWithTooltip
                                                                                iconName={'bi-arrow-repeat'}
                                                                                onClickHandler={reloadOldProcessSecondState}
                                                                                tooltipText={'Obnovit původní text druhého postupu přípravy'}
                                                                                tooltipPlacement={'left'}
                                                                                key={'repeat-btn-second'}
                                                                            />
                                                                        )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}></Col>
                                                        <Col>
                                                            <TextEditor
                                                                wrapperClass={"editor-wrapper-smaller"}
                                                                editorState={secondEditorState}
                                                                onEditorStateChangeHandler={setSecondEditorState}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-3">
                                                        <Row>
                                                            <Col xs={2}>
                                                                <Form.Label>BEZPEČNOSTNÍ POKYNY PRO PŘÍPRAVU</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className={"d-flex flex-wrap"}>
                                                                    {safetyProcesses.map((type) => {
                                                                        return (
                                                                            <div style={{
                                                                                width: '270px',
                                                                            }}
                                                                                key={type.id}
                                                                            >
                                                                                <Button
                                                                                    key={type.id}
                                                                                    name="safety"
                                                                                    className="mb-2"
                                                                                    onClick={() => {
                                                                                        console.debug('aa');
                                                                                        addTextToEditorStateHandler(type.id, safetyProcesses, safetyEditorState, setSafetyEditorState);
                                                                                    }}
                                                                                >
                                                                                    <i className="bi bi-plus"></i>
                                                                                    {type.name}
                                                                                </Button>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={2}></Col>
                                                            <Col>
                                                                <TextEditor
                                                                    editorState={safetyEditorState}
                                                                    onEditorStateChangeHandler={setSafetyEditorState}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </Row>
                                                <Row className="border border-2 pt-3 pb-3 rounded mb-3">
                                                    <Row>
                                                        <Form.Label className={"text-muted"}>Obal I.</Form.Label>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={true}
                                                            string={true}
                                                            input={selectedContainer}
                                                        >
                                                            <TextInputWithWhisperer
                                                                value={selectedContainer}
                                                                onChangeHandler={setSelectedContainer}
                                                                whispers={CONF_CONTAINERS}
                                                            />
                                                        </ErrorMessageForInput>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label className={"text-muted"}>Doba
                                                            použitelnosti I.</Form.Label>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={true}
                                                            string={true}
                                                            input={expirationFirst}
                                                        >
                                                            <TextInputWithWhisperer
                                                                value={expirationFirst}
                                                                onChangeHandler={setExpirationFirst}
                                                                whispers={CONF_EXPIRATION}
                                                            />
                                                        </ErrorMessageForInput>
                                                    </Row>
                                                    <Row className='mt-5'>
                                                        <Form.Label className={"text-muted"}>Obal II.</Form.Label>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={false}
                                                            string={true}
                                                            input={selectedSecondaryContainer}
                                                        >
                                                            <TextInputWithWhisperer
                                                                value={selectedSecondaryContainer}
                                                                onChangeHandler={setSelectedSecondaryContainer}
                                                                whispers={[
                                                                    'Kelímek typu Unguator®',
                                                                    'Volný text...'
                                                                ]}
                                                            />
                                                        </ErrorMessageForInput>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label className={"text-muted"}>Doba
                                                            použitelnosti II.</Form.Label>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={false}
                                                            string={true}
                                                            input={expirationSecond}
                                                        >
                                                            <TextInputWithWhisperer
                                                                value={expirationSecond}
                                                                onChangeHandler={setExpirationSecond}
                                                                whispers={CONF_EXPIRATION}
                                                            />
                                                        </ErrorMessageForInput>
                                                    </Row>
                                                </Row>
                                                <Row className="border border-2 pt-3 pb-3 rounded mb-3">
                                                    <Col>
                                                        <div>
                                                            <Form.Label className={"text-muted"}>Označení</Form.Label>
                                                        </div>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={true}
                                                            string={true}
                                                            input={selectedColor}
                                                        >
                                                            <div>
                                                                <LabelColorButton
                                                                    selected={selectedColor}
                                                                    color={"red"}
                                                                    onClickHandler={() => {
                                                                        setSelectedColor("red")
                                                                    }}
                                                                />
                                                                <LabelColorButton
                                                                    selected={selectedColor}
                                                                    color={"white"}
                                                                    onClickHandler={() => {
                                                                        setSelectedColor("white")
                                                                    }}
                                                                />
                                                            </div>
                                                        </ErrorMessageForInput>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <Form.Label className={"text-muted"}>Úhrada
                                                                pojišťovny</Form.Label>
                                                        </div>
                                                        <ErrorMessageForInput
                                                            show={showErrors}
                                                            required={true}
                                                            boolean={true}
                                                            input={insurancePayment}
                                                        >
                                                            <div>
                                                                <InsurancePaymentButton
                                                                    selected={insurancePayment}
                                                                    color={"green"}
                                                                    onClickHandler={() => {
                                                                        setInsurancePayment(true)
                                                                    }}
                                                                />
                                                                <InsurancePaymentButton
                                                                    selected={insurancePayment === false}
                                                                    color={"red"}
                                                                    onClickHandler={() => {
                                                                        setInsurancePayment(false)
                                                                    }}
                                                                />
                                                            </div>
                                                        </ErrorMessageForInput>

                                                        <Form.Group>
                                                            <Form.Label>Poznámka k uhradě</Form.Label>
                                                            <Form.Control
                                                                type="string"
                                                                value={noticeInsurancePayment}
                                                                onChange={(e) => {
                                                                    setNoticeInsurancePayment(e.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="border border-2 pt-3 pb-3 rounded mb-3">
                                                    <Row className="mb-1">
                                                        <TextInputWithWhisperer
                                                            value={noticeForPatient}
                                                            onChangeHandler={setNoticeForPatient}
                                                            label='Upozornění pro pacienta'
                                                            whispers={CONF_WARNING_PATIENT}
                                                        />
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <TextInputWithWhisperer
                                                            value={storageConditions}
                                                            onChangeHandler={setStorageConditions}
                                                            label='Podmínky uchovávání'
                                                            whispers={CONF_CONDITIONS}
                                                        />
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <TextInputWithWhisperer
                                                            value={control}
                                                            onChangeHandler={setControl}
                                                            label='Kontrola / vzhled'
                                                            whispers={CONF_CONTROL}
                                                        />
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <Col>
                                                            <div>
                                                                <Form.Label className={"text-muted"}>Režim výdeje</Form.Label>
                                                            </div>
                                                            <TextInputWithWhisperer
                                                                textArea={true}
                                                                value={freeTextSale}
                                                                onChangeHandler={setFreeTextSale}
                                                                whispers={[
                                                                    'Volně prodejné (nutný technologický předpis v lékárně)',
                                                                    'Výdej vázán na lékařský předpis',
                                                                    'Nutno ověřit vhodnost terapie a dávkování. Volný prodej možný dle uvážení lékárníka (IPLP obsahuje jen inoxia)',
                                                                    'Volný text',
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row className="border border-2 pt-3 pb-3 rounded mb-3">

                                                    <Row className="mb-1">
                                                    
                                                    <Form.Group>
                                                        <Form.Label>Zdroj receptury</Form.Label>
                                                            <PoorLinkEditor
                                                                editorState={sourceEditorState}
                                                                onEditorStateChangeHandler={(s)=>{
                                                                    if(!s.getCurrentContent().hasText()){
                                                                        setWhisperSource(true);
                                                                    } else {
                                                                        setWhisperSource(false);
                                                                    }
                                                                    setSourceEditorState(s);
                                                                }}
                                                            />
                                                        {whisperSource && (
                                                            <div className="shadow rounded" style={{
                                                                position: 'absolute',
                                                                zIndex: '10',
                                                                background: '#fff',
                                                                border: "1px solid #ccc",
                                                                padding: '0 16px 0 4px',
                                                            }}>
                                                                {
                                                                    CONF_SOURCES.map((whisp) => {
                                                                        return <div style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                                    key={whisp}
                                                                                    onClick={() => {
                                                                                        setSourceEditorState(getEditorStateFromHTML(whisp));
                                                                                        setWhisperSource(false);
                                                                                    }}
                                                                        >
                                                                            {whisp}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>)
                                                        }

                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-1">
                                                        <Form.Group>
                                                            <Form.Label>Poznámka</Form.Label>
                                                            <PoorLinkEditor
                                                                editorState={commentEditorState}
                                                                onEditorStateChangeHandler={setCommentEditorState}
                                                            />
                                                            {/* <Form.Control
                                                                type="string"
                                                                as="textarea"
                                                                rows={3}
                                                                value={comment}
                                                                onChange={(e) => {
                                                                    setComment(e.target.value);
                                                                }}
                                                            /> */}
                                                        </Form.Group>
                                                    </Row>
                                                </Row>
                                                <Button variant="success" type="submit">
                                                    Uložit
                                                </Button>

                                            </Form>

                                        </div>
                                    </div>
                                </Container>

                            )
                        }
                    </div>
                </div>


            </div>
        </>


    )
        ;
}
