import {useNavigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './css/fontawesome-free/css/all.min.css';
import './css/admin-style/admin-style.css';
import './css/datatables/dataTables.bootstrap4.min.css';
import './css/main/main.css';
import './css/main/draftjs.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Col} from "react-bootstrap";
import Router from './routes/Router'
import React, {useEffect, useState} from "react";
import {deleteAuthInfo, getAuthInfo, setAuthInfo} from "./utils/auth";
import axios from "axios";
import {deleteAgreementInfo, getAgreementInfo, setAgreementInfo} from "./utils/agreement";
import ReactGA from "react-ga4";


export const AppContext = React.createContext(null);

export default function App() {
    const navigate = useNavigate();

    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [agreed, setAgreed] = useState(null);

    ReactGA.initialize('G-G7QXS6Z525');

    useEffect(()=>{

        if (token === null){
            const authInfo = getAuthInfo();
            if (authInfo){
                setUser(authInfo.user);
                setToken(authInfo.token);
            }
        }
        if (agreed === null){
            const agreementInfo = getAgreementInfo();
            if (agreementInfo){
                setAgreed(true);
            }
        }
    },[])

    const login = (token, user) => {
        setToken(token);
        setUser(user);
        setAuthInfo(token, user);
    }

    const logout = () => {
        setToken(null);
        setUser(null);
        setAgreed(null);
        deleteAuthInfo();
        deleteAgreementInfo();
        navigate('/');
    }

    return (
        <>
            <AppContext.Provider value={{
                token: token,
                user: user,
                login: login,
                logout: logout,
                agreed: agreed,
                agree: () => {
                    setAgreed(true);
                    setAgreementInfo();
                },
                decline: () => setAgreed(false),
            }}>
                <Router/>

            </AppContext.Provider>
        </>
    )
        ;
}
