import {useContext} from "react";
import {AppContext} from "../App";

export default function ComponentForRole({role, component}){
    const context = useContext(AppContext);
    if (context.user && context.user.role === role){
        return component;
    } else {
        return <></>;
    }
}