export function isEmptyString(stringForValidate) {
    return (!stringForValidate || /^\s*$/.test(stringForValidate));
}

/**
 *
 * @param props.required - when checking if input is required
 * @param props.array - true when input is array, null or false otherwise
 * @param props.string - true when input is string, null or false otherwise
 * @param props.input - input with value for checking error
 * @param props.input - when input is string from WYSIWYG editor
 * @param props.show - show error message when
 * @param props.children
 * @returns {JSX.Element}
 * @constructor
 */
export function ErrorMessageForInput(props) {
    let error = false;

    if (props.required) {
        if (props.array) {
            error = props.input.length <= 0;
        } else if (props.string) {
            error = isEmptyString(props.input);
        } else if(props.boolean){
            error = (props.input === null || (props.input !== true && props.input !== false))
        } else {
            error = (props.input === null);
        }
    }

    return (
        <div className='d-flex flex-column'>
            <div style={(props.show && error) ? {
                border: '1px solid #cd0000',
                borderRadius: '6px',
            } : {}}>
                {props.children}
            </div>

            {(props.show && error) && (
                <div>
                    <span style={{
                        color: '#cd0000',
                    }}><i>Povinný</i></span>
                </div>
            )}

        </div>
    );
}