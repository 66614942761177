import React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

export default function OrderButton({setOrderHandler, itemName ,searchParams, numbers = false}){
    const order = searchParams.get('order');
    const item = searchParams.get('orderItem');

    const upClassName = numbers ? "bi bi-sort-numeric-up" : "bi bi-sort-alpha-up"; 
    const downClassName = numbers ? "bi bi-sort-numeric-down" : "bi bi-sort-alpha-down"; 
    const upText = numbers ? "Řadit od 0 po 9" : "Řadit od A po Z"; 
    const downText = numbers ? "Řadit od 9 po 0" : "Řadit od Z po A"; 

    return (
        <OverlayTrigger
            key={itemName+ "-overlay"}
            placement={'right'}
            overlay={
                <Tooltip id={itemName + "-tooltip"}>
                    {
                        order ? (order === 'DESC' ? (upText) : (downText) ) : (downText)
                    }
                </Tooltip>
            }
        >
            <span onClick={() => {
                if (order && (item === itemName)){
                    if (order === 'ASC'){
                        setOrderHandler({
                            order: 'DESC',
                            item: itemName,
                        })
                    } else {
                        setOrderHandler({
                            order: 'ASC',
                            item: itemName,
                        })
                    }
                } else {
                    setOrderHandler({
                        order: 'DESC',
                        item: itemName,
                    })
                }
            }} className='mr-2'>
                {
                    (order && (item === itemName)) ? (order === 'DESC' ? (<i className={downClassName}></i>) : (<i className={upClassName}></i>) ) : (<i className={upClassName}></i>)
                }
            </span>
        </OverlayTrigger>



    );
}