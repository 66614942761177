import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

export default function ButtonWithTooltip({keyProp, iconName, onClickHandler, tooltipText , tooltipPlacement}){
    return (
        <OverlayTrigger
            key={keyProp}
            placement={tooltipPlacement}
            overlay={
                <Tooltip id={keyProp}>
                    {tooltipText}
                </Tooltip>
            }
        >
            <Button
                onClick={()=>onClickHandler()}
                style={{
                    borderRadius: '35px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px 8px',
                    lineHeight: "1em"
                }}
            >
                {
                    iconName &&(
                        <i className={"bi " + iconName} style={{
                            fontSize: '1.7em'
                        }}></i>
                    )
                }
            </Button>
        </OverlayTrigger>
    );
}