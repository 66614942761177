import {isEmptyString} from "./form/ErrorMessageForInput";

export function validatePrescriptionForm(form, setShowErrors) {
    let valid = true;
    if (isEmptyString(form.name)) {
        console.log("Input 'name' is required");
        valid = false;
    }
    if (isEmptyString(form.selectedContainer)) {
        console.log("Input 'selectedContainer' is required");
        valid = false;
    }

    /* No needed
    if (isEmptyString(form.selectedSecondaryContainer)) {
        console.log("Input 'selectedSecondaryContainer' is required");
        valid = false;
    }
     */

    if (isEmptyString(form.selectedColor)) {
        console.log("Input 'color' is required");
        valid = false;
    }
    if (isEmptyString(form.expirationFirst)) {
        console.log("Input 'expirationFirst' is required");
        valid = false;
    }

    /* No needed
    if (isEmptyString(form.expirationSecond)) {
        console.log("Input 'expirationSecond' is required");
        valid = false;
    }
    */

    if (form.unavailableDrugs.length <= 0) {
        console.log("Array 'unavailableDrugs' cannot be empty");
        valid = false;
    }
    if (form.ingredients.length <= 0) {
        console.log("Array 'ingredients' cannot be empty");
        valid = false;
    }

    form.unavailableDrugs.forEach((item) => {
        if (isEmptyString(item.name)) {
            console.log("Input 'name' in array 'unavailableDrugs' is required");
            valid = false;
        }
    })

    /* No needed
    form.ingredients.forEach((item) => {
        if (isEmptyString(item.name) || isEmptyString(item.amount)) {
            console.log("Input 'name' and 'amount' in array 'ingredients' is required");
            valid = false;
        }
    })

     */

    /* No needed
    form.materials.forEach((item) => {
    if (isEmptyString(item.name) || isEmptyString(item.amount)) {
        console.log("Input 'name' and 'amount' in array 'materials' is required");
        valid = false;
    }
    })

     */

    if (form.selectedForm === null) {
        console.log("Input 'selectedForm' is required");
        valid = false;
    }
    if (form.insurancePayment === null) {
        console.log("Input 'insurence payment' is required");
        valid = false;
    }
    if (form.selectedIndication === null) {
        console.log("Input 'selectedIndication' is required");
        valid = false;
    }
    if (!valid) {
        setShowErrors(true);
        return false;
    } else {
        return true;
    }
}