import {Modal} from "react-bootstrap";

export default function FailModal({show}){
    return(
        <Modal
            centered
            show={show}
        >
            <Modal.Body className={"d-flex justify-content-center"}>
                            <span className={'text-danger'} style={{
                                fontSize: '5em',
                            }}>
                                <i className="bi bi-x-circle-fill"></i>
                            </span>
            </Modal.Body>
        </Modal>
    );
}